var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.products.length > 0 || _vm.welcomeProducts.length > 0
    ? _c(
        "div",
        {
          staticClass: "discount-list-slider",
          attrs: { id: `discount-list-slider-${_vm.config.categoryBlockId}` }
        },
        [
          _c("TitleRow", {
            attrs: {
              config: _vm.config,
              categoryBlockName: "DiscountsSlider",
              showMore: true
            }
          }),
          _c("ebsn-meta", {
            attrs: {
              target: _vm.config,
              path: `categoryblocktype_DiscountsSlider.DESCRIPTION`,
              tag: "div"
            }
          }),
          _c(
            "div",
            { staticClass: "slider discount-slider mx-n2" },
            [
              _c(
                "swiper",
                { ref: "swiperRef", attrs: { options: _vm.swiperOption } },
                [
                  _vm._l(_vm.products, function(product) {
                    return _c(
                      "swiper-slide",
                      { key: product.productId },
                      [
                        _c("CouponCard", {
                          staticClass: "my-1 mx-2",
                          attrs: {
                            coupon: product,
                            type: "discount",
                            userPoints: _vm.userPointsInt
                          },
                          on: { showDetails: _vm.showDetails }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.welcomeProducts, function(product) {
                    return _c(
                      "swiper-slide",
                      { key: product.productId },
                      [
                        _c("CouponCard", {
                          staticClass: "my-1 mx-2",
                          attrs: {
                            coupon: product,
                            title: "coupons.discounts.welcome",
                            type: "welcomeCoupon"
                          },
                          on: { showDetails: _vm.showActiveCoupon }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm.$ebsn.meta(
                _vm.config,
                "categoryblocktype_DiscountsSlider.SHOW_BULLETS",
                true
              ) &&
              (_vm.products.length > 0 || _vm.welcomeProducts.length > 0)
                ? _c("div", {
                    class: `swiper-pagination discount-list-pagination-${_vm.config.categoryBlockId}`,
                    attrs: {
                      id: `discount-list-pagination-${_vm.config.categoryBlockId}`
                    }
                  })
                : _c("div", { staticClass: "py-4" }),
              _vm.$ebsn.meta(
                _vm.config,
                "categoryblocktype_DiscountsSlider.SHOW_ARROWS",
                false
              ) &&
              (_vm.products.length > 0 || _vm.welcomeProducts.length > 0)
                ? _c("div", {
                    staticClass: "swiper-button-prev",
                    attrs: {
                      id: `discount-list-slider-prev-${_vm.config.categoryBlockId}`
                    }
                  })
                : _vm._e(),
              _vm.$ebsn.meta(
                _vm.config,
                "categoryblocktype_DiscountsSlider.SHOW_ARROWS",
                false
              ) &&
              (_vm.products.length > 0 || _vm.welcomeProducts.length > 0)
                ? _c("div", {
                    staticClass: "swiper-button-next",
                    attrs: {
                      id: `discount-list-slider-next-${_vm.config.categoryBlockId}`
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }