import CartService from "./cartService";
/* eslint-disable no-debugger */
import axios from "~/plugins/axios";
// import store from "../store";

export default {
  refreshLogin() {
    return CartService.getCart().then(data => {
      if (data && data.user) {
        return true;
      }
    });
  },

  login(username, password, rememberMe, token) {
    let params = new URLSearchParams();
    params.append("login", username);
    params.append("password", password);
    params.append("remember_me", rememberMe);
    params.append("show_sectors", global.config.showSectors);
    if (token) params.append("token", token);
    let url = "/ebsn/api/auth/login";
    return axios
      .post(url, params)
      .then(data => {
        if (data.data.response.status == 0) {
          // eslint-disable-next-line no-undef
          let session = vm.$cookies.get("X-Ebsn-Session");
          window.localStorage.setItem("X-Ebsn-Session", session);
          // eslint-disable-next-line no-undef
          let account = vm.$cookies.get("X-Ebsn-Account");
          window.localStorage.setItem("X-Ebsn-Account", account);
        }
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  logout() {
    return axios
      .post("/ebsn/api/auth/logout")
      .then(data => {
        if (data.data.response.status == 0) {
          localStorage.removeItem("X-Ebsn-Account");
          localStorage.removeItem("X-Ebsn-Session");
        }
        return data.data.data;
      })
      .catch(data => {
        return data.response;
      });
  }
};
