var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "address-selector fill-height" },
    [
      _c(
        "v-layout",
        { attrs: { "fill-height": "", column: "" } },
        [
          _vm.category &&
          _vm.category.metaData &&
          _vm.category.metaData.category_info
            ? _c(
                "h2",
                {
                  staticClass:
                    "secondary--text text-center font-weight-bold mt-3 mb-4 mb-sm-0"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.category.metaData.category_info.TITLE) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.category &&
          _vm.category.metaData &&
          _vm.category.metaData.category_info &&
          _vm.category.metaData.category_info.DESCRIPTION
            ? _c("p", {
                staticClass: "selector-subitle",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.category.metaData.category_info.DESCRIPTION
                  )
                }
              })
            : _vm._e(),
          _c("v-divider"),
          _vm.deliveryServices.length
            ? _c(
                "v-row",
                {
                  staticClass: "my-3 tabs",
                  attrs: {
                    "no-gutters": "",
                    justify: "space-between",
                    align: "center"
                  }
                },
                _vm._l(_vm.deliveryServices, function(service, idx) {
                  return _c(
                    "v-col",
                    {
                      key: service.deliveryServiceId,
                      attrs: { cols: _vm.deliveryServiceButtonsLength },
                      on: {
                        click: function($event) {
                          return _vm.fetchAddresses(service, idx)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tab d-flex flex-column align-center justify-center flex-grow-1 pa-4 mx-1 rounded-sm",
                          class:
                            service.deliveryServiceId ===
                            _vm.selectedDeliveryServiceId
                              ? `${service.cssClass} selected`
                              : `${service.cssClass}`
                        },
                        [
                          _c("img", {
                            staticClass: "mr-1",
                            attrs: {
                              src: _vm.$t(
                                `navbar.service.${service.deliveryServiceId}.icon`
                              ),
                              alt: "Immagine " + service.name,
                              contain: "",
                              height: _vm.$vuetify.breakpoint.xs ? 60 : 80
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            { attrs: { loading: _vm.loading, flat: "" } },
            [
              _c("div", { staticClass: "my-3 text-center text-sm-left" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "primary--text text-body-1 font-weight-bold mb-2"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `navbar.service.${_vm.deliveryService.deliveryServiceId}.deliveryText`
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c("div", { staticClass: "secondary--text text-body-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `navbar.service.${_vm.deliveryService.deliveryServiceId}.deliverySub`
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c(
                "v-list",
                { staticClass: "address-list pt-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.shippingAddress,
                        callback: function($$v) {
                          _vm.shippingAddress = $$v
                        },
                        expression: "shippingAddress"
                      }
                    },
                    _vm._l(_vm.filteredAddresses, function(address) {
                      return _c(
                        "v-list-item",
                        {
                          key: address.addressId,
                          attrs: { "two-line": "" },
                          on: {
                            click: function($event) {
                              return _vm.setAddress(address)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "font-weight-bold text-body-2 line-height-13"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(address.addressName) + " "
                                  )
                                ]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                {
                                  staticClass:
                                    "text-body-2 font-weight-bold line-height-13"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "navbar.address." +
                                            address.addressTypeId +
                                            ".list",
                                          address
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            { staticClass: "flex-row align-center" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            address.selected
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          large: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("$complete")]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        address.addressType == "home"
                                          ? "Indirizzo preferito"
                                          : "Negozio preferito"
                                      )
                                    )
                                  ])
                                ]
                              ),
                              address.addressType == "home"
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.edit(address)
                                        }
                                      }
                                    },
                                    [_vm._v("$edit")]
                                  )
                                : _vm._e(),
                              address.addressType == "home"
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-4",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.remove(address)
                                        }
                                      }
                                    },
                                    [_vm._v("$delete")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _vm.deliveryService.deliveryServiceId === 2
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        large: "",
                        depressed: "",
                        to: { name: "EditAddress" }
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("submit", false)
                        }
                      }
                    },
                    [_vm._v(" AGGIUNGI INDIRIZZO ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }