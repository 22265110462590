var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "product-qty" },
    [
      _c(
        "div",
        {
          staticClass: "qty-wrap rounded-sm",
          class: {
            "not-empty": _vm.parent.quantity > 0,
            "show-add-btn": _vm.parent.quantity < 1
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "minus",
              attrs: {
                "aria-label": "Diminuisci quantità",
                depressed: "",
                color: "grey lighten-1",
                small: ""
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.parent.minus(_vm.props.updateCart, {
                    infos: { ..._vm.props.selectedOptions }
                  })
                }
              }
            },
            [_c("v-icon", [_vm._v("$minus")])],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "long-press",
                  rawName: "v-long-press",
                  value: 300,
                  expression: "300"
                }
              ],
              staticClass: "val-cnt",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
                dblclick: _vm.parent.toggleShowInput,
                "long-press-start": _vm.parent.toggleShowInput,
                mousedown: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c("span", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.parent.quantity) + _vm._s(_vm.parent.unit))
              ]),
              _vm.parent.quantityPerUnit
                ? _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.parent.quantityPerUnit))
                  ])
                : _vm.parent.packageCount
                ? _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.parent.packageCount))
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "plus grey",
              attrs: {
                "aria-label": "Aumenta quantità",
                color: "grey lighten-1",
                small: "",
                depressed: ""
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.parent.plus(_vm.props.updateCart, {
                    infos: {
                      ..._vm.props.selectedOptions
                    }
                  })
                }
              }
            },
            [_c("v-icon", [_vm._v("$plus")])],
            1
          )
        ],
        1
      ),
      _vm.props.showCartButton
        ? _c(
            "v-btn",
            {
              staticClass: "add-to-cart-btn",
              attrs: {
                depressed: "",
                height: "50",
                "min-width": "50",
                color: "primary"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.parent.addToCart(
                    _vm.parent.quantity,
                    { infos: { ..._vm.selectedOptions } },
                    {
                      searchUid: _vm.props.product.searchUid,
                      position: _vm.props.position
                    }
                  )
                }
              }
            },
            [
              _c("v-icon", [_vm._v("$cart")]),
              _vm.props.label
                ? _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.props.label))
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }