var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-3 pt-6 px-3", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.$t("noActiveCard.title")))]),
          _c("div", { staticClass: "text-body-0" }, [
            _vm._v(_vm._s(_vm.message))
          ]),
          _c("div", { staticClass: "text-body-0 mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("noActiveCard.addNewCard")) + " ")
          ]),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "d-flex flex-wrap",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              },
              model: {
                value: _vm.isValid,
                callback: function($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t(
                            "register.card.step-0.placeholder.codiceCartaFedeltà"
                          )} *`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "number"
                        },
                        model: {
                          value: _vm.cardNumber,
                          callback: function($$v) {
                            _vm.cardNumber = $$v
                          },
                          expression: "cardNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c("ResponseMessage", {
                    staticClass: "mt-3",
                    attrs: { response: _vm.response }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-flex flex-column flex-md-row justify-sm-space-between justify-md-start"
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { md: "5" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                depressed: "",
                                color: "primary",
                                block: "",
                                rounded: "",
                                large: "",
                                loading: _vm.loading
                              },
                              on: { click: _vm.submit }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("register.card.step-0.btnConfirm")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mt-8",
              attrs: {
                depressed: "",
                color: "primary",
                block: "",
                rounded: "",
                large: "",
                outlined: "",
                loading: _vm.loading
              },
              on: { click: _vm.requestNewCard }
            },
            [_vm._v(_vm._s(_vm.$t("noActiveCard.requestNewCard")))]
          ),
          _c("div", { staticClass: "py-1" }, [
            _vm._v(_vm._s(_vm.$t("noActiveCard.goToStore")))
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }