var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-file-viewer" },
    [
      _c(
        "v-btn",
        {
          staticClass: "primary",
          attrs: { download: "", large: "", href: _vm.obj.value }
        },
        [
          _c("v-icon", { staticClass: "download-icon pr-2" }, [
            _vm._v("$download")
          ]),
          _vm._v(" SCARICA FILE ALLEGATO")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }