var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "product-option-selector pa-3 pa-sm-6",
      attrs: { flat: "", elevation: "0" }
    },
    [
      _c(
        "v-card-title",
        [
          _vm.$te("productPromoSelector.title")
            ? [_vm._v(" " + _vm._s(_vm.$t("productPromoSelector.title")) + " ")]
            : [_vm._v(" Vuoi applicare questa promozione? ")]
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c("product-promo", { attrs: { warehousePromo: _vm.warehousePromo } })
        ],
        1
      ),
      _vm.warehousePromo &&
      _vm.warehousePromo.view &&
      _vm.warehousePromo.view.selectablePromoText
        ? _c("div", {
            staticClass: "selectable-promo-descr",
            domProps: {
              innerHTML: _vm._s(_vm.warehousePromo.view.selectablePromoText)
            }
          })
        : _vm.$te("productPromoSelector.description")
        ? _c("div", { staticClass: "selectable-promo-descr" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("productPromoSelector.description")) + " "
            )
          ])
        : _c("div", { staticClass: "selectable-promo-descr" }, [
            _vm._v(
              ' Seleziona "Sì" se vuoi acquistare il prodotto vicino alla scadenza a prezzo scontato '
            )
          ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: { text: "" },
              on: { click: _vm.close }
            },
            [_vm._v(" No ")]
          ),
          _c("v-btn", { staticClass: "primary", on: { click: _vm.apply } }, [
            _vm._v(" Sì ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }