<template functional>
  <div class="title-row d-flex justify-space-between align-start">
    <ebsn-meta
      :target="props.config"
      :path="`categoryblocktype_${props.categoryBlockName}.TITLE`"
      tag="h2"
      cssClass="slider-title category-block-title"
      :class="'category-block-title'"
    />
    <v-btn
      v-if="
        props.showMore &&
          !parent.$ebsn.meta(
            props.config,
            'categoryblocktype_' + props.categoryBlockName + '.HIDE_LINK'
          )
      "
      color="primary"
      depressed
      text
      rounded
      class="align-self-end"
      :to="
        $options.calcLinkToGo(
          props.config,
          props.categoryBlockName,
          parent.$ebsn.meta
        )
      "
    >
      {{
        parent.$ebsn.meta(
          props.config,
          "categoryblocktype_" + props.categoryBlockName + ".BUTTON_TEXT",
          parent.$t("categoryBlock.productListSlider.showAll")
        )
      }}
      <v-icon small right>$arrowForward</v-icon>
    </v-btn>
  </div>
</template>
<style lang="scss">
.title-row {
  .v-btn .v-btn__content {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
<script>
export default {
  name: "TitleRow",
  props: {
    config: { type: Object, required: true },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false }
  },
  calcLinkToGo(config, categoryBlockName, getMetaFunction) {
    let categorySlug = getMetaFunction(
      config,
      "categoryblocktype_" + categoryBlockName + ".CATEGORY.slug"
    );
    if (categorySlug) {
      return {
        name: "Category",
        params: {
          pathMatch: categorySlug
        }
      };
    } else {
      return getMetaFunction(
        config,
        "categoryblocktype_" + categoryBlockName + ".LINK"
      );
    }
  }
};
</script>
