<template>
  <div class="expense-progress-wraper">
    <div class="progress-pointers">
      <div
        class="progress-pointer"
        v-for="(segment, index) in segments"
        :key="'progress-pointer-' + index"
        :class="{ inactive: points < segment.expense }"
        :style="{ left: 100 * ((index + 1) / segments.length) + '%' }"
      >
        <div class="outer-circle outer-circle-theme">
          {{ segment.name }}
        </div>
      </div>
    </div>
    <div class="progress-dividers">
      <div
        class="progress-divider progress-divider-theme"
        v-for="(segment, index) in segments"
        :key="'progress-divider-' + index"
        :class="{ inactive: points < segment.expense }"
        :style="{ left: 100 * ((index + 1) / segments.length) + '%' }"
      >
        <div></div>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress-bar progress-bar-theme" :style="{ width: percentage + '%' }"></div>
    </div>
    <div class="progress-values progress-values-theme mb-12">
      <div
        v-for="(segment, index) in segments"
        :key="'progress-value-' + index"
        :class="{ inactive: points < segment.expense }"
        :style="{ left: 100 * ((index + 1) / segments.length) + '%' }"
      >
        {{ segment.expense }}€ <span>{{ $t("coupons.discounts.onSpecificSector") }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.expense-progress-wraper {
  //padding: 10px 10px;
  margin-right: 23px;
  .progress-container {
    width: 100%;
    height: 20px;
    background-color: #f1f1f1;
    border-radius: 15px;
    position: relative;
    margin-top: 75px;
    margin-right: 13px;
    overflow: hidden;
  }

  .progress-bar-theme{
    background-color: #f0a3a3;
    background-image: linear-gradient(to right, #d50d52, #ecb0c3);
  }
  .progress-bar {
    height: 100%;
    border-radius: 15px;
    position: absolute;
    display: flex;
    border-radius: 15px;
    max-width: 100%;
  }
  .progress-bar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 20px 20px;
    background-repeat: repeat;
    overflow: hidden;
  }
  .progress-pointer {
    top: -56px;
    transform: translateX(-50%);
    position: absolute;
    .outer-circle-theme{
      border: 3px solid #d50d52;
      color: #d50d52;
    }
    .outer-circle {
      width: 40px;
      height: 40px;
      background-color: $white;
      border-radius: 50%;
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      .euro-symbol {
        font-weight: normal;
        font-size: 12px;
        padding-top: 3px;
      }
    }
  }
  .progress-pointer.inactive {
    .outer-circle {
      border-color: var(--v-grey-darken1) !important;
      color: var(--v-grey-darken1) !important;
    }
  }
  .progress-pointer:after {
    left: 20px;
    transform: translateX(-50%);
    content: "";
    position: absolute;
    margin-top: 3px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #d50d52;
    z-index: 2;
  }

  .progress-pointer.inactive::after {
    border-top-color: var(--v-grey-darken1) !important;
  }

  .progress-divider-theme{
    border: 2px solid #d50d52;
    background-color: white;
  }

  .progress-divider-theme > div{
    background-color: #d50d52;
  }

  .progress-divider {
    position: absolute;
    transform: translate(-50%, -3px);
    width: 26px;
    height: 26px;
    z-index: 2;
    border-radius: 50%;
    padding: 4px;
  }
  .progress-divider.inactive {
    border-color: var(--v-grey-darken1) !important;
  }
  .progress-divider > div {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .progress-divider.inactive > div {
    background-color: var(--v-grey-darken1) !important;
  }
  .progress-values,
  .progress-dividers,
  .progress-pointers {
    position: relative;
    width: 100%;
  }
  .progress-values {
    margin-top: 8px;
  }
  .progress-values-theme > div {
    color: #d50d52;
  }
  .progress-values > div {
    position: absolute;
    width: min-content;
    text-align: center;
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    span {
      font-weight: normal;
    }
  }
  .progress-values > div.inactive {
    color: var(--v-grey-darken1) !important;
    opacity: 0.7;
  }

  .progress-bar-theme{
    background-color: #f0eba0;
    background-image: linear-gradient(to right, #d1b313, #f0eba0);
}
.progress-bar-block a span{
    color: #F29F05 ;
}
.outer-circle-theme{
    border: 3px solid #F7AF00 ;
    color: #F7AF00 ;
}
.progress-divider-theme{
    border: 2px solid #d1b313 ;
    background-color: white ;
}
.progress-divider-theme > div{
    background-color: #d1b313 ;
}
.progress-values-theme > div {
    color: #F6AA00 ;
}

}
</style>
<script>
export default {
  name: "ExpenseProgressBar",
  props: {
    points: { type: Number, required: true },
    segments: { type: Array, required: true }
  },
  data() {
    return {
      nextDiscount: null,
      pointsTillDiscount: null
    };
  },
  computed: {
    percentage() {
      let lowerBound;
      let upperBound;
      let segmentNumber;
      let segmentSize = (100 / this.segments.length).toFixed(2);
      if (this.segments[this.segments.length - 1].expense <= this.points) {
        return 100;
      }
      for (let i = 0; i < this.segments.length; i++) {
        if (this.segments[i].expense > this.points) {
          lowerBound = i == 0 ? 0 : this.segments[i - 1].expense;
          upperBound = this.segments[i].expense;
          segmentNumber = i;
          this.saveNext(this.segments[i]);
          break;
        }
      }
      return (
        segmentNumber * segmentSize +
        ((this.points - lowerBound) / (upperBound - lowerBound)) * segmentSize
      ).toFixed(2);
    }
  },
  methods: {
    saveNext(data) {
      this.nextDiscount = data.name;
      this.pointsTillDiscount = data.expense - this.points;
    }
  }
};
</script>
