import { render, staticRenderFns } from "./AddressSelector.vue?vue&type=template&id=600fb070&scoped=true"
import script from "./AddressSelector.vue?vue&type=script&lang=js"
export * from "./AddressSelector.vue?vue&type=script&lang=js"
import style0 from "./AddressSelector.vue?vue&type=style&index=0&id=600fb070&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600fb070",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rifatosmani/workspace/Abbondanza/ebsn-front-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('600fb070')) {
      api.createRecord('600fb070', component.options)
    } else {
      api.reload('600fb070', component.options)
    }
    module.hot.accept("./AddressSelector.vue?vue&type=template&id=600fb070&scoped=true", function () {
      api.rerender('600fb070', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/delivery/AddressSelector.vue"
export default component.exports