var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-2 pt-12 px-2", attrs: { fluid: "" } },
    [
      _vm.coupon
        ? _c("div", [
            _c("h2", { staticClass: "primary--text text-center" }, [
              _vm.type == "discount"
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("coupons.discounts.discountCoupon")) + " "
                    )
                  ])
                : _vm._e(),
              _vm._v(_vm._s(_vm.coupon.name) + " ")
            ]),
            _vm.coupon.metaData && _vm.coupon.metaData.product_long_description
              ? _c("p", {
                  staticClass: "px-1",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.coupon.metaData.product_long_description.DESCRIPTION
                    )
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm.coupon
        ? _c("CouponCard", {
            attrs: {
              coupon: _vm.coupon,
              details: true,
              type: _vm.type,
              userPoints: _vm.userPoints
            }
          })
        : _vm._e(),
      _vm.type === "discount"
        ? _c(
            "ol",
            { staticClass: "mt-3 text-body-3" },
            _vm._l(_vm.disclaimerMessages, function(disclaimer, index) {
              return _c("li", { key: "disclaimer" + index }, [
                _vm._v(" " + _vm._s(_vm.$t(disclaimer)) + " ")
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }