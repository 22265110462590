<template>
  <div class="advent-calendar">
          <!-- Overlay for the GIF -->
    <v-overlay v-model="showOverlay" opacity="0.8">
      <img class="overlay-img"
        src="/img_layout/advent_calendar/paccho-open.gif"
      />
    </v-overlay>
    <ResponseMessage :response="response" class="mt-3" />
    <div class="header">
      <img
          src="/img_layout/advent_calendar/logo.png"
          style="width: 60%;"
        />
    </div>
    <div class="calendar-grid">
      <div
        v-for="(day, index) in calendar"
        @click="clickCoupon(day)"
        :key="index"
        class="calendar-day"
        :style="getBackgroundImage(day)"
      >
        <span class="day-number" :class="(day.status =='Redeemed' || day.status == 'Expired' || day.status == 'Missed') ? 'day-number-disabled':'day-number-enabled'">{{ day.day }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import couponsService from '@/service/couponsService';
import ActiveCouponDetail from '../coupon/ActiveCouponDetail.vue';
import ResponseMessage from '../ResponseMessage.vue';
export default {
  name: "AdventCalendarBlock",
  components: { ResponseMessage },
  data() {
    return {
      showOverlay:false,
      response: {},
    };
  },
  props: {
    calendar: { type: [Array,null], required: true },
    availableCoupon: { type: Object, required: false },
  },
  watch:{
    availableCoupon(newVal, oldVal) {
      console.log(oldVal);
    if(newVal != null && newVal != undefined){
      this.clickCoupon(newVal);
    }
  },
  },
  async mounted(){
    console.log(this.calendar);
    console.log(this.availableCoupon);
  },
  methods: {
    async clickCoupon(day){
      switch(day.status){
       case "Available":
          try{
            const res = await couponsService.activateDiscount(day.product.id,"calendar");
            let temp = this.calendar.find(item => item.productId === day.productId);
            day.barCode = res.data.value.response.dinamiche.dinamica[0].buoni.lstBuoni.buono[0].upc;
            temp.barCode = day.barCode;
            temp.status = "Booked";
            await this.showGifOverlay();
            this.showActiveCoupon(day,"");
          }catch(e){
            e.response.errors[0].error=null;
            this.response = e.response;
            console.log(this.response);
          }
          break;
        case "Booked":
          await this.showActiveCoupon(day,"");
          break;
        case "Missed":
          this.response = {"errors":[{"error":"Questo buono non  estato attivato nel suo giorno."}]};
          break;
        case"Expired":
          this.response = {"errors":[{"error":"Questo buono e scaduto"}]};
          break;
        case 'Upcoming':
          this.response = {"errors":[{"error":"Questo buono non si puo ancora attivare"}]};
          break;
        case 'Redeemed':
          this.response = {"errors":[{"error":"Questo coupon e stato gia redento"}]};
          break;
      }
    },
    async showActiveCoupon(coupon, type) {
      let res = await this.$dialog.show(ActiveCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: coupon,
        type: type,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showGifOverlay() {
      this.showOverlay = true;
      // Return a promise that resolves after 3 seconds
      return new Promise(resolve => {
        setTimeout(() => {
          this.showOverlay = false;
          resolve();
        }, 2190);
      });
    },

    unlockDay(index) {
      if (this.days[index].locked) {
        this.$set(this.days, index, { ...this.days[index], locked: false });
      }
    },
    howItWorks() {
      alert("Ecco come funziona il calendario!");
    },
    getBackgroundImage(day) {
      // Set different background image depending on the lock status
      return {
        backgroundImage: (() => {
          switch (day.status) {
            case "Missed":
              return "url('/img_layout/advent_calendar/paccho-missed.png')"; // locked background image
            case 'Booked':
              return "url('/img_layout/advent_calendar/paccho-booked.png')"; // unlocked background image
            case 'Expired':
              return "url('/img_layout/advent_calendar/paccho-used.png')"; // optional default case
            case 'Available':
              return "url('/img_layout/advent_calendar/paccho-available.png')"; // optional default case
            case 'Redeemed':
              return "url('/img_layout/advent_calendar/paccho-used.png')"; // optional default case
            default:
              return "url('/img_layout/advent_calendar/paccho-available.png')"; // optional default case
         }
        })(),
      };
    }
  },
};
</script>

<style scoped>

.overlay-img{
  width: 100%;
}

.advent-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h1 {
  margin-bottom: 20px;
}

.calendar-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 5px; /* Optional spacing between cells */
  background-image: url('/img_layout/advent_calendar/bg.png');
  background-repeat: repeat;
}

.calendar-day {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns text content to the bottom */
  align-items: center;       /* Centers content horizontally */
  background-size: contain;  /* Keeps image within cell without cropping */
  background-position: center;
  background-repeat: no-repeat; /* Ensures no tiling of background */
}

.day-number {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 900;
  font-size: 25px;
  color: #e72c33;  
  margin-bottom: 5px; /* Adds a bit of space above the text */
  line-height: 1.1 !important;
}

.day-number-disabled {
  color: #43454b;
}
.header{
  text-align: center;
}
</style>
