var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "py-3", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "pb-0 pt-0 text-body-1 font-weight-bold" },
        [_vm._v("Seleziona una lista a cui aggiungere:")]
      ),
      _c(
        "v-card-text",
        { staticClass: "pb-0 pt-1" },
        [
          _vm.product
            ? _c("span", { staticClass: "qty-to-add text-body-2" }, [
                _c("i", [
                  _vm._v(
                    _vm._s(_vm.quantityToAdd) +
                      " " +
                      _vm._s(
                        _vm.product.productInfos.WEIGHT_UNIT_SELLING
                          ? _vm.product.productInfos.WEIGHT_UNIT_SELLING
                          : _vm.product.priceUnitDisplay
                      )
                  )
                ]),
                _vm._v(" di " + _vm._s(_vm.product.name) + ": ")
              ])
            : _vm._e(),
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "300px" }
            },
            [
              _vm._l(_vm.whishlistLists, function(list, index) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: list.id,
                      on: {
                        click: function($event) {
                          return _vm.selectList(list.id)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-badge",
                            {
                              attrs: {
                                color: "primary",
                                inline: "",
                                content: list.size,
                                value: list.size > 0
                              }
                            },
                            [_vm._v(" " + _vm._s(list.name) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { key: index })
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "justify-center" },
        [
          _c(
            "v-form",
            {
              staticClass: "d-flex",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createAndSelect.apply(null, arguments)
                }
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Crea una nuova lista",
                  "hide-details": "auto",
                  dense: "",
                  required: ""
                },
                model: {
                  value: _vm.listName,
                  callback: function($$v) {
                    _vm.listName = $$v
                  },
                  expression: "listName"
                }
              }),
              _c(
                "v-btn",
                { attrs: { type: "submit", small: "", color: "primary" } },
                [_vm._v("CREA")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }