var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preferred-store" },
    [
      _c("SelectedStore", { attrs: { showOpenTime: true } }),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-end mt-1 mb-3" },
        [
          !_vm.$ebsn.meta(
            _vm.config,
            "categoryblocktype_PreferredStore.HIDE_LINK"
          )
            ? _c(
                "v-btn",
                {
                  staticClass: "show-all-btn",
                  attrs: {
                    color: "primary",
                    depressed: "",
                    text: "",
                    rounded: "",
                    small: "",
                    to: { name: "Leaflet" }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$ebsn.meta(
                        _vm.config,
                        "categoryblocktype_PreferredStore.BUTTON_TEXT"
                      )
                    )
                  ),
                  _c("v-icon", { attrs: { "x-small": "", right: "" } }, [
                    _vm._v("$arrowForward")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }