export default {
  computed: {},
  methods: {
    onSuccess(result) {
      console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    },
    onError(msg) {
      console.log("Sharing failed with message: " + msg);
    }
  }
};
