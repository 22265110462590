var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "shortcuts-menu d-flex justify-center justify-sm-center mb-6 mt-3"
    },
    [
      _c("div", { staticClass: "item mx-1 mx-sm-2" }, [
        _c(
          "div",
          {
            directives: [{ name: "ripple", rawName: "v-ripple" }],
            staticClass: "img-wrap",
            on: {
              click: function($event) {
                return _vm.$router.push(
                  `/gala-world/magazine?article_class_id=1`
                )
              }
            }
          },
          [
            _c("img", {
              attrs: { src: "/img_layout/homepage_links/ricette.svg" }
            })
          ]
        ),
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("shortcutsMenu.recipes")))
        ])
      ]),
      _c("div", { staticClass: "item mx-1 mx-sm-2" }, [
        _c(
          "div",
          {
            directives: [{ name: "ripple", rawName: "v-ripple" }],
            staticClass: "img-wrap",
            on: {
              click: function($event) {
                return _vm.$router.push(`/gala-world/sostenibilita`)
              }
            }
          },
          [
            _c("img", {
              attrs: { src: "/img_layout/homepage_links/sostenibilita.svg" }
            })
          ]
        ),
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("shortcutsMenu.sustainability")))
        ])
      ]),
      _c("div", { staticClass: "item mx-1 mx-sm-2" }, [
        _c(
          "div",
          {
            directives: [{ name: "ripple", rawName: "v-ripple" }],
            staticClass: "img-wrap",
            on: {
              click: function($event) {
                return _vm.$router.push(
                  `/gala-world/magazine?article_class_id=2`
                )
              }
            }
          },
          [_c("img", { attrs: { src: "/img_layout/homepage_links/news.svg" } })]
        ),
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("shortcutsMenu.news")))
        ])
      ]),
      _c("div", { staticClass: "item mx-1 mx-sm-2" }, [
        _c(
          "div",
          {
            directives: [{ name: "ripple", rawName: "v-ripple" }],
            staticClass: "img-wrap",
            on: {
              click: function($event) {
                return _vm.$router.push(`/gala-world/contact-form`)
              }
            }
          },
          [
            _c("img", {
              attrs: { src: "/img_layout/homepage_links/contatti.svg" }
            })
          ]
        ),
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("shortcutsMenu.contactUs")))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }