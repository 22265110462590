<template>
  
  <div class="response-message">
    <v-alert
      type="warning"
      dense
      transition="scale-transition"
      v-for="(error, index) in warnings"
      :key="index"
    >
      <span v-html="error.error" />
    </v-alert>
    <v-alert
      type="info"
      :icon="false"
      prominent
      text
      color="primary"
      transition="scale-transition"
      v-for="(info, index) in infos"
      :key="index"
    >
      <span v-html="info.info" />
    </v-alert>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import ErrorMessage from "./ErrorMessage.vue";
export default {
  name: "ResponseMessage",
  props: { response: { type: Object, required: false }, title: { type: String, required: false }, message: { type: Object, required: false } },
  data(){
    return {
        dialog: true,
        showErrorMessage:false,
      }  },
  components: {  },
  computed: {
    warnings() {
      return isEmpty(this.response.warnings) ? [] : this.response.warnings;
    },
    infos() {
      return isEmpty(this.response.infos) ? [] : this.response.infos;
    }
  },
  watch: { 
        response: function(newVal, oldVal) { // watch it
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)

          let errorMessage = '';

          if (Array.isArray(newVal.errors) && newVal.errors.length !== 0) {
            errorMessage = newVal.errors[0].error || 'Si è verificato un errore, riprova tra qualche minuto.';
          } else if (typeof newVal.errors === 'object' && newVal.errors !== null && 
                      ((newVal.infos != null && newVal.infos.length == 0) || newVal.infos == null) &&
                      ((newVal.warnings != null && newVal.warnings.length == 0) || newVal.warnings == null)) {
            errorMessage = newVal.errors.error || 'Si è verificato un errore, riprova tra qualche minuto.';
          }

          if (errorMessage) {
            this.$dialog.show(ErrorMessage, {
              waitForResult: true,
              fullscreen: false,
              message: errorMessage,
              title: "Errore"
            });
          }
        }
  },

  methods: {
    normalize(string) {
      return string.replace(/\\n/g, "\n");
    },
    closeDialog(){
      this.showErrorMessage = false;
      this.$emit('close');
    }
  }
};
</script>
