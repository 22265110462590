import { render, staticRenderFns } from "./StaticText.vue?vue&type=template&id=e9f3296a"
import script from "./StaticText.vue?vue&type=script&lang=js"
export * from "./StaticText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rifatosmani/workspace/Abbondanza/ebsn-front-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e9f3296a')) {
      api.createRecord('e9f3296a', component.options)
    } else {
      api.reload('e9f3296a', component.options)
    }
    module.hot.accept("./StaticText.vue?vue&type=template&id=e9f3296a", function () {
      api.rerender('e9f3296a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/categoryBlock/StaticText.vue"
export default component.exports