var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "product-card-big pa-4" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", lg: "3", xl: "2" } },
            [
              _c("v-img", {
                staticClass: "product-img align-self-center",
                attrs: {
                  width: "170",
                  height: "170",
                  src: _vm.product.mediaURL,
                  onerror: "this.onerror=null;this.src='/no-icon.png'",
                  alt: `Immagine ${_vm.product.name}`,
                  title:
                    _vm.product.name +
                    " (" +
                    _vm.product.codInt +
                    "-" +
                    _vm.product.codVar +
                    ")"
                }
              })
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", sm: "8", lg: "9", xl: "10" } }, [
            _c(
              "div",
              {
                staticClass:
                  "description d-flex flex-column justify-left w-100 h-100 mt-3 ml-1 ml-sm-0"
              },
              [
                _c("div", { staticClass: "name font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.product.name) +
                      " " +
                      _vm._s(_vm.product.shortDescr) +
                      " " +
                      _vm._s(_vm.product.description) +
                      " "
                  )
                ]),
                _c("ebsn-meta", {
                  attrs: {
                    target: _vm.product,
                    path: "productInfos.EXTENDED_DESCRIPTION",
                    tag: "div"
                  }
                }),
                _c("v-spacer"),
                _vm.selectedOptions.customWeight
                  ? _c(
                      "div",
                      {
                        staticClass: "cart-item-info",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openOptionsModal(
                              _vm.product.selectOptions
                            )
                          }
                        }
                      },
                      [
                        _c("em", [
                          _vm._v(
                            "Grammatura: " +
                              _vm._s(_vm.selectedOptions.customWeight) +
                              "gr"
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.product.available > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "actions",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _vm.product.priceDisplay
                          ? _c("product-price", {
                              attrs: { product: _vm.product }
                            })
                          : _vm._e(),
                        _c("v-spacer"),
                        _c("product-qty", {
                          attrs: {
                            selectedOptions: _vm.selectedOptions,
                            product: _vm.product,
                            item: _vm.item,
                            position: _vm.position
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "actions justify-center text-center text-caption error--text font-weight-bold"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("product.notAvailable")) + " "
                        )
                      ]
                    )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }