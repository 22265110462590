<template>
  <div>
    <div class="calendar-container">
      <template v-for="(segment, index) in segments"  >
        <div v-if="calendar[index]=='N'" class="image-container" :key="index">
          <img   src="/logo/calendar.svg" class="image">
          <div class="overlay-text">{{$t("calendarBar.month")}}</div>
          <div class="overlay-text-segment">{{ segment }}</div>
        </div>
        <div v-if="calendar[index]=='Y'" class="image-container" :key="index">
          <img   src="/logo/calendar-blue.svg" class="image">
          <div class="overlay-text">{{$t("calendarBar.month")}}</div>
          <div class="overlay-text-segment blue">{{ segment }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss">

.calendar-container{
  display: flex;
  justify-content: space-around;
}
.image-container {
  position: relative;
  display: inline-block;
  width: 18%; /* Set the desired width */
}

.image {
  width: 100%;
  display: block;
  color: red !important;
}
.overlay-text {
  position: absolute;
  color: white;
  font-size: 14px;
  text-align: center;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;

  /*
  top: 20%;
  left: 55%;
  */
}
.overlay-text-segment {
  position: absolute;
  color: #BBBBBB;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  top: 50%;
  left:50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.blue{
    color: #0468be;
  }

.expense-progress-wraper {
  //padding: 10px 10px;
  margin-right: 23px;
  .progress-container {
    width: 100%;
    height: 20px;
    background-color: #f1f1f1;
    border-radius: 15px;
    position: relative;
    margin-top: 75px;
    margin-right: 13px;
    overflow: hidden;
  }

  .progress-bar-theme{
    background-color: #f0a3a3;
    background-image: linear-gradient(to right, #d50d52, #ecb0c3);
  }
  .progress-bar {
    height: 100%;
    border-radius: 15px;
    position: absolute;
    display: flex;
    border-radius: 15px;
    max-width: 100%;
  }
  .progress-bar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 20px 20px;
    background-repeat: repeat;
    overflow: hidden;
  }
  .progress-pointer {
    top: -56px;
    transform: translateX(-50%);
    position: absolute;
    .outer-circle {
      width: 40px;
      height: 40px;
      background-color: $white;
      border-radius: 50%;
      display: flex;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      .euro-symbol {
        font-weight: normal;
        font-size: 12px;
        padding-top: 3px;
      }
    }
  }
  .progress-pointer.inactive {
    .outer-circle {
      border-color: var(--v-grey-darken1) ;
      color: var(--v-grey-darken1) ;
    }
  }
  .progress-pointer:after {
    left: 20px;
    transform: translateX(-50%);
    content: "";
    position: absolute;
    margin-top: 3px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #d50d52;
    z-index: 2;
  }

  .progress-pointer.inactive::after {
    border-top-color: var(--v-grey-darken1) ;
  }

  .progress-divider-theme{
    border: 2px solid #d50d52;
    background-color: white;
  }

  .progress-divider-theme > div{
    background-color: #d50d52;
  }

  .progress-divider {
    position: absolute;
    transform: translate(-50%, -3px);
    width: 26px;
    height: 26px;
    z-index: 2;
    border-radius: 50%;
    padding: 4px;
  }
  .progress-divider.inactive {
    border-color: var(--v-grey-darken1) ;
  }
  .progress-divider > div {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .progress-divider.inactive > div {
    background-color: var(--v-grey-darken1) ;
  }
  .progress-values,
  .progress-dividers,
  .progress-pointers {
    position: relative;
    width: 100%;
  }
  .progress-values {
    margin-top: 8px;
  }
  .progress-values-theme > div {
    color: #d50d52;
  }
  .progress-values > div {
    position: absolute;
    width: min-content;
    text-align: center;
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    span {
      font-weight: normal;
    }
  }
  .progress-values > div.inactive {
    color: var(--v-grey-darken1) ;
    opacity: 0.7;
  }

  .progress-bar-theme{
    background-color: #f0eba0;
    background-image: linear-gradient(to right, #d1b313, #f0eba0);
}
.outer-circle-theme{
    border: 3px solid #F7AF00 ;
    color: #F7AF00 ;
}
.progress-divider-theme{
    border: 2px solid #d1b313 ;
    background-color: white ;
}
.progress-divider-theme > div{
    background-color: #d1b313 ;
}
.progress-values-theme > div {
    color: #F6AA00 ;
}

}
</style>
<script>
export default {
  name: "ExpenseCalendarBar",
  props: {
    calendar: { type: Array, required: true },
    segments: { type: Array, required: true }
  },
  data() {
    return {
    };
  },
  methods: {

  }
};
</script>
