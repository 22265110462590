var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        label: _vm.field.label,
                        required: _vm.field.required == "1",
                        readonly: "",
                        outlined: ""
                      },
                      model: {
                        value: _vm.field.value,
                        callback: function($$v) {
                          _vm.$set(_vm.field, "value", $$v)
                        },
                        expression: "field.value"
                      }
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.xmenu,
        callback: function($$v) {
          _vm.xmenu = $$v
        },
        expression: "xmenu"
      }
    },
    [
      _c("v-date-picker", {
        ref: "picker",
        attrs: {
          locale: "it-IT",
          min: "1910-01-01",
          "first-day-of-week": "1",
          type: "date",
          "no-title": ""
        },
        on: {
          input: function($event) {
            _vm.xmenu = false
          }
        },
        model: {
          value: _vm.field.value,
          callback: function($$v) {
            _vm.$set(_vm.field, "value", $$v)
          },
          expression: "field.value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }