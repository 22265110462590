var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "product-option-selector pa-3 pa-sm-6",
      attrs: { flat: "", elevation: "0" }
    },
    [
      _vm.showTitle
        ? _c("h3", { staticClass: "text-center my-3" }, [
            _vm._v(" E' necessario selezionare tutte le opzioni ")
          ])
        : _vm._e(),
      _vm._l(_vm.optionsArray, function(option) {
        return _c(
          "div",
          { key: option.parameterName },
          [
            _vm.mode === "radio"
              ? _c(
                  "v-radio-group",
                  {
                    attrs: { dense: "", row: _vm.radioRow },
                    on: {
                      change: function($event) {
                        return _vm.optionChanged(option)
                      }
                    },
                    model: {
                      value: _vm.selectedOptions[option.parameterName],
                      callback: function($$v) {
                        _vm.$set(_vm.selectedOptions, option.parameterName, $$v)
                      },
                      expression: "selectedOptions[option.parameterName]"
                    }
                  },
                  _vm._l(option.options, function(opt) {
                    return _c("v-radio", {
                      key: opt.name,
                      attrs: { value: opt.value },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [_c("span", {}, [_vm._v(_vm._s(opt.key))])]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm.mode === "select"
              ? _c("v-select", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    items: option.options,
                    label: option.name,
                    "item-text": "key",
                    "item-value": "value"
                  },
                  on: {
                    change: function($event) {
                      return _vm.optionChanged(option)
                    }
                  },
                  model: {
                    value: _vm.selectedOptions[option.parameterName],
                    callback: function($$v) {
                      _vm.$set(_vm.selectedOptions, option.parameterName, $$v)
                    },
                    expression: "selectedOptions[option.parameterName]"
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _c(
        "v-card-actions",
        { staticClass: "d-flex flex-row justify-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary",
              attrs: { text: "", disabled: !_vm.isValid },
              on: { click: _vm.addToCart }
            },
            [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }