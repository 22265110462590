var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading &&
    this.config &&
    this.config.proposals &&
    this.config.proposals.length > 0
    ? _c(
        "div",
        { staticClass: "recipe-slider-container" },
        [
          _c("TitleRow", {
            attrs: {
              config: _vm.config,
              categoryBlockName: "RecipeSlider",
              showMore: true
            }
          }),
          _c(
            "div",
            { staticClass: "slider news-slider" },
            [
              _c(
                "swiper",
                { ref: "swiperRef", attrs: { options: _vm.swiperOption } },
                _vm._l(_vm.config.proposals, function(proposal) {
                  return _c(
                    "swiper-slide",
                    { key: proposal.id },
                    [
                      _c("ProposalCardOverlay", {
                        attrs: {
                          proposal: proposal,
                          position: _vm.config.templateBlock.codInt
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.$ebsn.meta(
                _vm.config,
                "categoryblocktype_NewsSlider.SHOW_BULLETS",
                true
              ) && _vm.config.proposals.length > 1
                ? _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: {
                      id: `news-slider-pagination-${_vm.config.categoryBlockId}`
                    }
                  })
                : _vm._e(),
              _vm.$ebsn.meta(
                _vm.config,
                "categoryblocktype_NewsSlider.SHOW_ARROWS",
                false
              ) && _vm.config.proposals.length > 1
                ? _c("div", {
                    staticClass: "swiper-button-prev",
                    attrs: {
                      id: `news-slider-prev-${_vm.config.categoryBlockId}`
                    }
                  })
                : _vm._e(),
              _vm.$ebsn.meta(
                _vm.config,
                "categoryblocktype_NewsSlider.SHOW_ARROWS",
                false
              ) && _vm.config.proposals.length > 1
                ? _c("div", {
                    staticClass: "swiper-button-next",
                    attrs: {
                      id: `news-slider-next-${_vm.config.categoryBlockId}`
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }