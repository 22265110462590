var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-img", {
    attrs: {
      id: "static - image",
      src: _vm.$ebsn.meta(_vm.config, _vm.categoryblocktype_StaticImage.IMAGE)
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }