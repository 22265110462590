<template>
  <v-container fluid class="pb-2 pt-12 px-2">
    <div v-if="coupon">
      <h2 class="primary--text text-center">
        <span v-if="type == 'discount'"
          >{{ $t("coupons.discounts.discountCoupon") }} </span
        >{{ coupon.name }}
      </h2>
      <p
        class="px-1"
        v-if="coupon.metaData && coupon.metaData.product_long_description"
        v-html="coupon.metaData.product_long_description.DESCRIPTION"
      ></p>
    </div>
    <CouponCard
      v-if="coupon"
      :coupon="coupon"
      :details="true"
      :type="type"
      :userPoints="userPoints"
    />
    <ol class="mt-3 text-body-3" v-if="type === 'discount'">
      <li
        v-for="(disclaimer, index) in disclaimerMessages"
        :key="'disclaimer' + index"
      >
        {{ $t(disclaimer) }}
      </li>
    </ol>
  </v-container>
</template>
<style lang="scss"></style>

<script>
import CouponCard from "@/components/coupon/CouponCard.vue";
import CouponsService from "@/service/couponsService";
import matomoService from "@/service/analytics/matomoService";

export default {
  name: "CouponDetail",
  props: {
    couponSlug: { type: String, required: true },
    type: { type: String, required: true },
    userPoints: { type: Number, default: 0 }
  },
  components: { CouponCard },
  data() {
    return {
      coupon: null,
      disclaimerMessages: []
    };
  },
  mounted(){
    matomoService.trackPageView("/couponDetails");
  },
  methods: {},
  async created() {
    await CouponsService.getCouponBySlug(this.couponSlug).then(data => {
      console.log(data);
      this.coupon = data;
    });
    this.disclaimerMessages = this.geti18nObject(
      "coupons.discounts.disclaimer"
    );
  }
};
</script>
