var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "intersect",
          rawName: "v-intersect.once",
          value: _vm.handleView,
          expression: "handleView",
          modifiers: { once: true }
        }
      ],
      staticClass: "proposal-img",
      attrs: {
        "aria-label": `Vai a ${_vm.proposal.name}`,
        rounded: "md",
        href: _vm.link,
        elevation: "0",
        id: `proposal-image-${_vm.proposal.id}`
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleLink.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "v-img",
        {
          attrs: {
            eager: "",
            src: _vm.src,
            title: _vm.proposal.imgDescription,
            alt: _vm.proposal.imgAlt
          }
        },
        [
          _vm.proposal.content
            ? _c("div", {
                staticClass: "overlay",
                domProps: { innerHTML: _vm._s(_vm.proposal.content) }
              })
            : _vm._e(),
          _vm.$ebsn.meta(_vm.proposal, "category_proposal_type.SHOW_BTN", false)
            ? _c(
                "v-btn",
                {
                  attrs: {
                    href: _vm.link,
                    color: "secondary",
                    block: "",
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleLink.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(" Scopri di più ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }