var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "response-message" },
    [
      _vm._l(_vm.warnings, function(error, index) {
        return _c(
          "v-alert",
          {
            key: index,
            attrs: {
              type: "warning",
              dense: "",
              transition: "scale-transition"
            }
          },
          [_c("span", { domProps: { innerHTML: _vm._s(error.error) } })]
        )
      }),
      _vm._l(_vm.infos, function(info, index) {
        return _c(
          "v-alert",
          {
            key: index,
            attrs: {
              type: "info",
              icon: false,
              prominent: "",
              text: "",
              color: "primary",
              transition: "scale-transition"
            }
          },
          [_c("span", { domProps: { innerHTML: _vm._s(info.info) } })]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }