var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advent-calendar" },
    [
      _c(
        "v-overlay",
        {
          attrs: { opacity: "0.8" },
          model: {
            value: _vm.showOverlay,
            callback: function($$v) {
              _vm.showOverlay = $$v
            },
            expression: "showOverlay"
          }
        },
        [
          _c("img", {
            staticClass: "overlay-img",
            attrs: { src: "/img_layout/advent_calendar/paccho-open.gif" }
          })
        ]
      ),
      _c("ResponseMessage", {
        staticClass: "mt-3",
        attrs: { response: _vm.response }
      }),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "calendar-grid" },
        _vm._l(_vm.calendar, function(day, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "calendar-day",
              style: _vm.getBackgroundImage(day),
              on: {
                click: function($event) {
                  return _vm.clickCoupon(day)
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "day-number",
                  class:
                    day.status == "Redeemed" ||
                    day.status == "Expired" ||
                    day.status == "Missed"
                      ? "day-number-disabled"
                      : "day-number-enabled"
                },
                [_vm._v(_vm._s(day.day))]
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticStyle: { width: "60%" },
        attrs: { src: "/img_layout/advent_calendar/logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }