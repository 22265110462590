<template>
  <div
    class="proposal-tutorial-slide d-flex flex-column swiper-page"
    :class="'proposal-tutorial-slide-' + proposal.id"
    @click="handleLink"
  >
    <v-img
      class="proposal-img"
      :src="getImage(proposal)"
      :alt="proposal.name"
    />
    <v-card flat class="bottom-fixed">
      <v-card-title class="proposal-title">
        {{ proposal.descr }}
      </v-card-title>
      <v-card-text class="content" v-html="proposal.content"> </v-card-text>
      <v-card-actions class="flex-column mb-5">
        <v-btn
          v-if="$ebsn.meta(proposal, 'category_proposal_type.SHOW_BTN', false)"
          depressed
          block
          color="primary"
          class="mb-2"
          @click="skipTutorial()"
          :to="link"
        >
          {{
            $ebsn.meta(proposal, "category_proposal_type.BTN_TEXT", "Scopri")
          }}
        </v-btn>
        <div class="actions w-100">
          <v-btn
            @click="openLogin()"
            color="primary"
            x-large
            block
            depressed
            rounded
          >
            {{ $t("tutorial.login") }}
          </v-btn>
          <v-btn
            @click="skipTutorial()"
            class="noLogin"
            color="default"
            large
            block
            depressed
            text
            rounded
          >
            {{ $t("tutorial.continueAsGuest") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style lang="scss">
.proposal-tutorial-slide {
  background-position: center;
  background-size: cover;
  .bottom-fixed {
    position: fixed;
    background-color: $white;
    bottom: 0px;
    width: 100%;
    min-height: 400px;
    border-radius: 25px 25px 0 0;
    padding: 50px 16px 20px 16px;
  }
  .proposal-img {
    top: 0px;
    position: fixed;
    width: 100%;
    height: calc(100vh - 375px);
  }
  .proposal-title {
    font-size: 28px !important;
    color: var(--v-secondary-base);
    margin-top: 30px;
    font-weight: 600 !important;
  }
  .content {
    font-size: 17px;
    min-height: 75px;
  }
  .proposal-title,
  .content {
    text-align: start !important;
    p {
      margin-bottom: 0px;
    }
  }
  .swiper-pagination {
    transform: none;
    justify-content: start;
    padding-left: 16px;
  }
  .actions {
    min-height: 50px;
    .noLogin {
      margin-top: 15px;
      .v-btn__content {
        font-size: 14px;
      }
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalTutorialSlide",
  mixins: [banner],
  data() {
    return {};
  },
  methods: {
    skipTutorial() {
      this.$emit("skipTutorial");
    },
    openLogin() {
      this.$emit("openLogin");
    },
    getImage(proposal) {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(proposal, prop, proposal.img);
    }
  }
};
</script>
