var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", [_vm._v(_vm._s(_vm.data.label))]),
    _c(
      "ul",
      _vm._l(_vm.data.options, function(option) {
        return _c(
          "li",
          { key: option.key },
          [
            _c("v-checkbox", {
              attrs: { label: option.label, value: option.value },
              on: { change: _vm.update },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }