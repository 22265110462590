var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_ProductTab.TITLE",
          tag: "h2"
        }
      }),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_categoryblocktype_ProductTab.DESCRIPTION",
          tag: "div"
        }
      }),
      _c(
        "v-tabs",
        {
          attrs: {
            color: _vm.$ebsn.meta(
              _vm.config,
              "categoryblocktype_ProductTab.TABS_COLOR"
            )
          },
          model: {
            value: _vm.selectedProduct,
            callback: function($$v) {
              _vm.selectedProduct = $$v
            },
            expression: "selectedProduct"
          }
        },
        [
          _c("v-tabs-slider"),
          _vm._l(_vm.products, function(product) {
            return _c("v-tab", { key: product.productId }, [
              _c("strong", [_vm._v(_vm._s(_vm.proposal.descr))]),
              _vm._v(" " + _vm._s(_vm.proposal.content) + " ")
            ])
          })
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.selectedProduct,
            callback: function($$v) {
              _vm.selectedProduct = $$v
            },
            expression: "selectedProduct"
          }
        },
        _vm._l(_vm.products, function(product) {
          return _c(
            "v-tab-item",
            { key: product.productId },
            [_c("product-card-big", { attrs: { product: product } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }