var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "privacy-form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-col",
                    { staticClass: "privacy-container", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "font-weight-semibold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("profile.disclaimerPrivacyTitle")) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c("v-checkbox", {
                            staticClass: "disclaimer-checkbox",
                            attrs: { rules: _vm.checkboxRules },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("i18n", {
                                      staticClass: "pre-wrap mt-2 text-body-2",
                                      staticStyle: { color: "black" },
                                      attrs: {
                                        path: "profile.disclaimerMainReduced2",
                                        tag: "span"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "link1",
                                          fn: function() {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-decoration-underline font-weight-semibold",
                                                  staticStyle: {
                                                    "font-size": "13px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.handleRedirect(
                                                        "https://www.galasupermercati.it/privacy-note-legali"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "profile.disclaimerMainReduced2Link"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.checked,
                              callback: function($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked"
                            }
                          }),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("profile.marketingDisclaimer")) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-around" },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "inline-radio",
                                  attrs: {
                                    rules: [_vm.requiredRules()],
                                    row: ""
                                  },
                                  model: {
                                    value: _vm.userData.marketing,
                                    callback: function($$v) {
                                      _vm.$set(_vm.userData, "marketing", $$v)
                                    },
                                    expression: "userData.marketing"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "pr-10",
                                    attrs: { label: "Si", value: "1" }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "pl-10",
                                    attrs: { label: "No", value: "0" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.userData.marketing == "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "v-messages theme--light error--text",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-messages__wrapper" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "v-messages__message" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profile.privacyMarketing.alert"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.userData.marketing === "1"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "font-weight-semibold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("profile.contactChannels")
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("profile.contactChannelsDesc")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center my-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-grow-1 pr-3" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("profile.emailPromo")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ios-style-switch mr-2"
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              inset: "",
                                              color: "primary",
                                              "hide-details": "",
                                              ripple: false,
                                              "false-value": "0",
                                              "true-value": "1"
                                            },
                                            model: {
                                              value: _vm.userData.canaleMail,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "canaleMail",
                                                  $$v
                                                )
                                              },
                                              expression: "userData.canaleMail"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center my-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-grow-1 pr-3" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("profile.sms")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ios-style-switch mr-2"
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              inset: "",
                                              color: "primary",
                                              "hide-details": "",
                                              ripple: false,
                                              "false-value": "0",
                                              "true-value": "1"
                                            },
                                            model: {
                                              value: _vm.userData.canaleSms,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "canaleSms",
                                                  $$v
                                                )
                                              },
                                              expression: "userData.canaleSms"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center my-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-grow-1 pr-3" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("profile.notifications")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ios-style-switch mr-2"
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              inset: "",
                                              color: "primary",
                                              "hide-details": "",
                                              ripple: false,
                                              "false-value": "0",
                                              "true-value": "1"
                                            },
                                            model: {
                                              value: _vm.userData.canalePush,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "canalePush",
                                                  $$v
                                                )
                                              },
                                              expression: "userData.canalePush"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c("div", [
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("profile.marketingDisclaimer2")) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-around" },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "inline-radio",
                                  attrs: {
                                    rules: [_vm.requiredRules()],
                                    row: ""
                                  },
                                  model: {
                                    value: _vm.userData.profilazione,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.userData,
                                        "profilazione",
                                        $$v
                                      )
                                    },
                                    expression: "userData.profilazione"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "pr-10",
                                    attrs: { label: "Si", value: "1" }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "pl-10",
                                    attrs: { label: "No", value: "0" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.userData.profilazione == "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "v-messages theme--light error--text",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-messages__wrapper" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "v-messages__message" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profile.privacyProfiling.alert"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }