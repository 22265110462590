var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "proposal-tutorial-slide d-flex flex-column swiper-page",
      class: "proposal-tutorial-slide-" + _vm.proposal.id,
      on: { click: _vm.handleLink }
    },
    [
      _c("v-img", {
        staticClass: "proposal-img",
        attrs: { src: _vm.getImage(_vm.proposal), alt: _vm.proposal.name }
      }),
      _c(
        "v-card",
        { staticClass: "bottom-fixed", attrs: { flat: "" } },
        [
          _c("v-card-title", { staticClass: "proposal-title" }, [
            _vm._v(" " + _vm._s(_vm.proposal.descr) + " ")
          ]),
          _c("v-card-text", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.proposal.content) }
          }),
          _c(
            "v-card-actions",
            { staticClass: "flex-column mb-5" },
            [
              _vm.$ebsn.meta(
                _vm.proposal,
                "category_proposal_type.SHOW_BTN",
                false
              )
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        depressed: "",
                        block: "",
                        color: "primary",
                        to: _vm.link
                      },
                      on: {
                        click: function($event) {
                          return _vm.skipTutorial()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.proposal,
                              "category_proposal_type.BTN_TEXT",
                              "Scopri"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "actions w-100" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        "x-large": "",
                        block: "",
                        depressed: "",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.openLogin()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tutorial.login")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "noLogin",
                      attrs: {
                        color: "default",
                        large: "",
                        block: "",
                        depressed: "",
                        text: "",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.skipTutorial()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("tutorial.continueAsGuest")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }