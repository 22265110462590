var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "d-flex flex-wrap",
      on: {
        submit: function($event) {
          $event.preventDefault()
        }
      },
      model: {
        value: _vm.isValid,
        callback: function($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.isRegisterWithCard
            ? _c(
                "v-col",
                { staticClass: "mb-2", attrs: { cols: "12", md: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t(
                        "register.card.step-0.placeholder.codiceCartaFedeltà"
                      )} *`,
                      hint: _vm.$t("register.card.step-0.placeholder.ccfHint"),
                      "persistent-hint": "",
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "number",
                      disabled: _vm.isFieldDisabled,
                      "append-icon": "$barcodeScan",
                      loading: _vm.loadingCard
                    },
                    on: { "click:append": _vm.scanCard },
                    model: {
                      value: _vm.userData.fidelity,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "fidelity", $$v)
                      },
                      expression: "userData.fidelity"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.askFirstName
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t(
                        "register.card.step-0.placeholder.name"
                      )} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      disabled: _vm.isFieldDisabled
                    },
                    model: {
                      value: _vm.userData.firstName,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "firstName", $$v)
                      },
                      expression: "userData.firstName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.askLastName
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t(
                        "register.card.step-0.placeholder.surname"
                      )} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      disabled: _vm.isFieldDisabled
                    },
                    model: {
                      value: _vm.userData.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "lastName", $$v)
                      },
                      expression: "userData.lastName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showButtons
            ? _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex flex-column flex-md-row justify-sm-space-between justify-md-start"
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { md: "5" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            color: "primary",
                            block: "",
                            rounded: "",
                            large: "",
                            loading: _vm.loading
                          },
                          on: { click: _vm.submit }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("register.card.step-0.btnConfirm"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }