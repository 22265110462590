<template>
  <v-btn
    rounded
    block
    color="white"
    x-large
    depressed
    class="primary--text"
    @click="doLogin()"
    >{{ label }}</v-btn
  >
</template>
<script>
import login from "~/mixins/login";

export default {
  name: "LoginBtn",
  mixins: [login],
  props: {
    label: { type: String, required: true }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
