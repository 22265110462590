var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "intersect",
          rawName: "v-intersect.once",
          value: _vm.handleView,
          expression: "handleView",
          modifiers: { once: true }
        }
      ],
      staticClass: "proposal-card-overlay",
      class: _vm.$ebsn.meta(
        _vm.proposal,
        "metaData.category_proposal_type.TEXT_POSITION"
      ),
      style: _vm.cardStyle,
      attrs: { href: _vm.link, id: `proposal-card-overlay-${_vm.proposal.id}` },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleLink.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "v-img",
        {
          attrs: {
            eager: "",
            src: _vm.imgSrc,
            alt: _vm.proposal.imgAlt,
            title: _vm.proposal.imgDescription,
            id: `proposal-image-${_vm.proposal.id}`
          }
        },
        [
          !_vm.$ebsn.meta(
            _vm.proposal,
            "category_proposal_type.HIDE_BLACK_LAYER"
          )
            ? _c("div", { staticClass: "blurred-bg" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "text-overlay" },
            [
              _vm.$ebsn.meta(
                _vm.proposal,
                "metaData.category_proposal_type.ICON"
              )
                ? _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: _vm.$ebsn.meta(
                        _vm.proposal,
                        "metaData.category_proposal_type.ICON"
                      ),
                      alt: "icon"
                    }
                  })
                : _vm._e(),
              _vm.proposal.descr
                ? _c("v-card-title", {
                    domProps: { innerHTML: _vm._s(_vm.proposal.descr) }
                  })
                : _vm._e(),
              _vm.proposal.content
                ? _c("v-card-text", {
                    domProps: { innerHTML: _vm._s(_vm.proposal.content) }
                  })
                : _vm._e(),
              _vm.$ebsn.meta(
                _vm.proposal,
                "metaData.category_proposal_type.TEXT_BTN"
              )
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: _vm.link,
                            outlined: "",
                            color: _vm.$ebsn.meta(
                              _vm.proposal,
                              "metaData.category_proposal_type.COLOR_BTN",
                              "white"
                            )
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ebsn.meta(
                                _vm.proposal,
                                "metaData.category_proposal_type.TEXT_BTN"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }