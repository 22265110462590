var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "LeafletSlider-wh-row",
      attrs: { id: `LeafletSlider-wh-${_vm.config.categoryBlockId}` }
    },
    [
      _c("ebsn-style", {
        attrs: {
          target: _vm.config,
          path: "metaData.categoryblocktype_LeafletSlider.CSS_STYLE",
          styleWrapper: `#LeafletSlider-wh-${_vm.config.categoryBlockId}`
        }
      }),
      _c(
        "v-container",
        { staticClass: "py-0", attrs: { fluid: "" } },
        [
          this.leaflets.length > 0
            ? _c("ebsn-meta", {
                class: "category-block-title",
                attrs: {
                  target: _vm.config,
                  path: "categoryblocktype_LeafletSlider.TITLE",
                  tag: "h2"
                }
              })
            : _vm._e(),
          _c("ebsn-meta", {
            attrs: {
              target: _vm.config,
              path: "categoryblocktype_LeafletSlider.DESCRIPTION",
              tag: "div"
            }
          }),
          _vm.leaflets
            ? _c(
                "div",
                { staticClass: "py-2", staticStyle: { position: "relative" } },
                [
                  _c(
                    "swiper",
                    { attrs: { options: _vm.swiperOption } },
                    _vm._l(_vm.leaflets, function(leaflet) {
                      return _c(
                        "swiper-slide",
                        { key: leaflet.leafletId },
                        [_c("LeafletCard", { attrs: { leaflet: leaflet } })],
                        1
                      )
                    }),
                    1
                  ),
                  _vm.$ebsn.meta(
                    _vm.config,
                    "categoryblocktype_LeafletSlider.SHOW_BULLETS",
                    true
                  ) && _vm.leaflets.length > 1
                    ? _c("div", {
                        staticClass: "swiper-pagination",
                        attrs: {
                          id: `leaflet-pagination-${_vm.config.categoryBlockId}`
                        }
                      })
                    : _vm._e(),
                  _vm.$ebsn.meta(
                    _vm.config,
                    "categoryblocktype_LeafletSlider.SHOW_ARROWS",
                    false
                  ) && _vm.leaflets.length > 1
                    ? _c("div", {
                        staticClass: "swiper-button-prev",
                        attrs: {
                          id: `leaflet-slider-prev-${_vm.config.categoryBlockId}`
                        }
                      })
                    : _vm._e(),
                  _vm.$ebsn.meta(
                    _vm.config,
                    "categoryblocktype_LeafletSlider.SHOW_ARROWS",
                    false
                  ) && _vm.leaflets.length > 1
                    ? _c("div", {
                        staticClass: "swiper-button-next",
                        attrs: {
                          id: `leaflet-slider-next-${_vm.config.categoryBlockId}`
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }