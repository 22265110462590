<template>
  <div
    :id="`NavigationCard-wh-${config.categoryBlockId}`"
    class="NavigationCard-wh-row"
  >
    <ebsn-style
      :target="config"
      path="metaData.categoryblocktype_NavigationCard.CSS_STYLE"
      :styleWrapper="`#NavigationCard-wh-${config.categoryBlockId}`"
    />
    <v-card v-if="$ebsn.meta(
              config,
              'categoryblocktype_NavigationCard.IMG_URL',
              false
            )" class="nav-card d-flex" :to="calcLinkToGo(config, $ebsn.meta)">
      <div class="card-image-wraper">
        <img
          class="card-image"
          :src="
            $ebsn.meta(
              config,
              'categoryblocktype_NavigationCard.IMG_URL',
              false
            )
          "
          onerror="this.onerror=null;this.src='/no-icon.png'"
        />
      </div>
      <div class="card-content">
        <div class="title">
          <ebsn-meta
            :target="config"
            path="categoryblocktype_NavigationCard.TITLE"
            tag="span"
            :class="'category-block-title'"
          />
        </div>
        <div class="subtitle">
          <ebsn-meta
            :target="config"
            path="categoryblocktype_NavigationCard.DESCRIPTION"
            tag="span"
          />
        </div>
      </div>
    </v-card>
    <v-card v-else class="nav-card d-flex" :to="calcLinkToGo(config, $ebsn.meta)">
      <div class="card-content-image">
        <div v-if="$ebsn.meta(
              config,
              'categoryblocktype_NavigationCard.TITLE',
              false
            )" class="title">
          <ebsn-meta
            :target="config"
            path="categoryblocktype_NavigationCard.TITLE"
            tag="span"
            :class="'category-block-title'"
          />
        </div>
        <div v-if="$ebsn.meta(
              config,
              'categoryblocktype_NavigationCard.DESCRIPTION',
              false
            )" class="subtitle">
          <ebsn-meta
            :target="config"
            path="categoryblocktype_NavigationCard.DESCRIPTION"
            tag="span"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>
<style lang="scss">
.NavigationCard-wh-row {
  .v-card {
    min-height: 160px;
  }
  .card-image-wraper {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-image {
      width: 100%;
      max-width: 169px;
      flex: 0 0 auto;
    }
  }

  .card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    .title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px !important;
      line-height: 18px;
      position: relative;
    }
    .title::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 45px;
      height: 2px;
      background-color: currentColor;
    }
    .subtitle {
      line-height: 16px;
    }
  }
  .card-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    .title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px !important;
      line-height: 18px;
      position: relative;
    }
    .title::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 45px;
      height: 2px;
      background-color: currentColor;
    }
    .subtitle {
      line-height: 16px;
    }
  }
}
</style>
<script>
import categoryBlockType from "./categoryBlockType";

export default {
  name: "NavigationCard",
  components: {},
  props: {
    config: { type: Object, required: true }
  },
  data() {
    return {};
  },
  methods: {
    calcLinkToGo(config, getMetaFunction) {
      let categorySlug = getMetaFunction(
        config,
        "categoryblocktype_NavigationCard.CATEGORY.slug"
      );
      if (categorySlug) {
        return {
          name: "Category",
          params: {
            pathMatch: categorySlug
          }
        };
      } else {
        return getMetaFunction(config, "categoryblocktype_NavigationCard.LINK");
      }
    }
  },
  mixins: [categoryBlockType],
  async mounted() {}
};
</script>
