var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column align-center registration-completed-container mt-6",
      staticStyle: { "text-align": "center" }
    },
    [
      _c("img", {
        style: { height: "70px" },
        attrs: { src: "/logo/logo.png", alt: "Logo" }
      }),
      _c(
        "v-row",
        { staticClass: "first-part" },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass:
                  "default--text font-weight-semibold title-text pt-5"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("register.registrationCompleted.title")) +
                    " "
                )
              ]
            ),
            _c("div", { staticClass: "sub-text" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("register.registrationCompleted.subtitle")) +
                  " "
              )
            ])
          ])
        ],
        1
      ),
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "mail-click" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("register.registrationCompleted.linkEmail")) +
              " "
          )
        ])
      ]),
      _c("v-col", { attrs: { cols: "12" } }),
      _c(
        "v-col",
        { staticClass: "pt-3", staticStyle: { width: "80%" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                rounded: "",
                block: "",
                "x-large": "",
                color: "primary"
              },
              on: { click: _vm.goToLogin }
            },
            [_vm._v(_vm._s(_vm.$t("register.registrationCompleted.goToLogin")))]
          )
        ],
        1
      ),
      _c("div", { staticClass: "divider" }, [_c("v-divider")], 1),
      _c(
        "v-row",
        { staticClass: "pt-2 second-part", staticStyle: { width: "80%" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "didnt-receive-mail font-weight-semibold" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("register.registrationCompleted.didntReceiveMail")
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("register.registrationCompleted.checkSpam")) +
                    " "
                )
              ]
            ),
            _c("div", {
              staticClass: "didnt-receive-mail font-weight-semibold"
            })
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.disableSendEmail,
                    rounded: "",
                    outlined: "",
                    block: "",
                    "x-large": "",
                    color: "primary"
                  },
                  on: { click: _vm.resendRegistrationEmail }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("register.registrationCompleted.reSendEmail"))
                  )
                ]
              )
            ],
            1
          ),
          _c("v-col", { staticClass: "pt-10", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "font-weight-semibold need-help" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("register.registrationCompleted.needHelp")) +
                  " "
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "font-weight-semibold text-decoration-underline contact",
                on: {
                  click: function($event) {
                    return _vm.goToContactForm()
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("register.registrationCompleted.contactUs")) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }