var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-3 pt-6 px-3", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("h4", [
            _vm._v(_vm._s(_vm.title ? _vm.title : _vm.$t("common.error")))
          ]),
          _c("span", [_vm._v(_vm._s(_vm.message))]),
          _c(
            "v-btn",
            {
              staticClass: "mt-8 error-button",
              attrs: {
                depressed: "",
                color: "secondary",
                block: "",
                rounded: "",
                outlined: ""
              },
              on: {
                click: function($event) {
                  return _vm.closeDialog()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("common.ok")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }