var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      ref: "contentToPrint",
      staticClass: "active-coupon-detail pb-0 pt-12 px-0",
      class: _vm.customClass,
      attrs: { fluid: "", "fill-height": "", id: "contentToPrint" }
    },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between",
              attrs: { xs12: "" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-center text-center px-2"
                },
                [
                  _vm.type == "welcomeCoupon"
                    ? _c("h2", [_vm._v(" " + _vm._s(_vm.formatedDesc) + " ")])
                    : _vm.type == "adventCalendarDiscount"
                    ? _c("h2", { staticClass: "advent-calendar-discount" }, [
                        _vm._v(" " + _vm._s(_vm.coupon.product.name) + " ")
                      ])
                    : _c(
                        "h2",
                        {
                          class:
                            _vm.type == "activeCashbackDiscount" ? "hcolor" : ""
                        },
                        [
                          _vm.type == "activeDiscount" ||
                          _vm.type == "activeCashbackDiscount"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("coupons.discounts.discountCoupon")
                                  ) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(_vm._s(_vm.coupon.product.name) + " "),
                          _vm.type == "activeCashbackDiscount"
                            ? _c("span", { staticClass: "small-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "coupons.discounts.onSpecificSectorSubheader"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                  _c("barcode", {
                    staticClass: "align-self-center",
                    attrs: {
                      value: _vm.coupon.barCode,
                      format: "EAN13",
                      displayValue: false,
                      height: "80px",
                      flat: ""
                    }
                  }),
                  _c("div", { staticClass: "text-body-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("coupons.discounts.numericalCode")) +
                        " "
                    )
                  ]),
                  _c("h1", { staticClass: "default--text mt-0" }, [
                    _vm._v(_vm._s(_vm.coupon.barCode))
                  ]),
                  _vm.type == "activeCashbackDiscount"
                    ? _c("div", { staticClass: "mb-7" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("coupons.discounts.onSpecificSector2")
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("coupons.discounts.valid", {
                                startDate: _vm.coupon.startRetreat,
                                endDate: _vm.coupon.endRetreat
                              })
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.couponDetails
                    ? _c("div", {
                        staticClass: "text-body-0 px-5",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.couponDetails.metaData
                              .product_long_description_active.DESCRIPTION
                          )
                        }
                      })
                    : _vm._e(),
                  _c(
                    "ol",
                    { staticClass: "mt-3 text-body-3 disclaimer-messages" },
                    _vm._l(_vm.disclaimerMessages, function(disclaimer, index) {
                      return _c("li", { key: "disclaimer" + index }, [
                        _vm._v(" " + _vm._s(disclaimer) + " ")
                      ])
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "bottom-section d-flex flex-column align-center grey lighten-1 text-center px-2 w-100 py-6 mt-3"
                },
                [
                  _c("img", {
                    staticClass: "coupon-img rounded-lg",
                    attrs: {
                      src: _vm.coupon.product.mediaURL,
                      onerror: "this.onerror=null;this.src='/no-icon.png'",
                      alt:
                        "Coupon-" +
                        (_vm.type == "activeDiscount"
                          ? _vm.coupon.barCode
                          : _vm.coupon.name)
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pt-2 pb-4 text-body-0 default--text text-decoration-underline",
                      attrs: { "data-html2canvas-ignore": "true" },
                      on: { click: _vm.print }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "default" } },
                        [_vm._v("$download")]
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("coupons.discounts.downloadYourVoucher")
                          )
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }