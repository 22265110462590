var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "static-page ninja-form" },
        [
          _vm.form
            ? _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  !_vm.formSent
                    ? _c(
                        "v-form",
                        {
                          ref: "formRef",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.sortedFields, function(field, idx) {
                              return _c(
                                "v-col",
                                {
                                  key: field.id,
                                  class:
                                    field.container_class +
                                    " col-num-" +
                                    idx +
                                    " " +
                                    (field.type === "hidden" ? " d-none" : ""),
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(_vm.decodeComponent(field.type), {
                                    ref: field.id,
                                    refInFor: true,
                                    tag: "component",
                                    class: field.element_class || null,
                                    attrs: {
                                      id: field.id,
                                      dense: _vm.mergedOptions.dense,
                                      outlined: _vm.mergedOptions.outlined,
                                      filled: _vm.mergedOptions.filled,
                                      solo: _vm.mergedOptions.solo,
                                      flat: _vm.mergedOptions.flat,
                                      depressed: _vm.mergedOptions.depressed,
                                      "background-color":
                                        _vm.mergedOptions.bgcolor,
                                      "persistent-hint": "",
                                      label: field.label,
                                      placeholder: field.placeholder,
                                      hint: _vm.stripHTML(field.desc_text),
                                      disabled: field.disable_input == "1",
                                      required: field.required == "1",
                                      rules: _vm.decodeRules(field),
                                      autocomplete: _vm.decodeAutocomplete(
                                        field.type
                                      ),
                                      items: field.options
                                        ? field.options.map(prop => ({
                                            text: prop.label,
                                            value: prop.value
                                          }))
                                        : null,
                                      type:
                                        field.type === "number" ||
                                        field.type === "submit"
                                          ? field.type
                                          : null,
                                      min:
                                        field.type === "number"
                                          ? field.num_min
                                          : null,
                                      max:
                                        field.type === "number"
                                          ? field.num_max
                                          : null,
                                      step:
                                        field.type === "number"
                                          ? field.num_step
                                          : null,
                                      accept: field.upload_types,
                                      field:
                                        field.type === "date" ||
                                        field.type === "submit" ||
                                        field.type === "checkbox"
                                          ? field
                                          : null,
                                      menu:
                                        field.type === "date" ? _vm.menu : null,
                                      html:
                                        field.type === "html"
                                          ? field.default
                                          : null,
                                      data:
                                        field.type === "listcheckbox" ||
                                        field.type === "listradio" ||
                                        field.type === "listmultiselect"
                                          ? field
                                          : null
                                    },
                                    on: { checkBoxChanged: _vm.setValue },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(field.label)
                                                },
                                                on: { click: _vm.clicked }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        },
                                        field.help_text
                                          ? {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function({
                                                              on
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " $info "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.stripHTML(
                                                              field.help_text
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          : null
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: field.value,
                                      callback: function($$v) {
                                        _vm.$set(field, "value", $$v)
                                      },
                                      expression: "field.value"
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formSent
                    ? _c("v-alert", {
                        attrs: { type: "success" },
                        domProps: { innerHTML: _vm._s(_vm.successMessage) }
                      })
                    : _vm._e(),
                  _vm.invalidForm
                    ? _c("v-alert", {
                        staticClass: "my-4",
                        attrs: { dense: "", type: "error" },
                        domProps: { innerHTML: _vm._s(_vm.errorMessage) }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }