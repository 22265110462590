<template>
  <div class="preferred-store">
    <SelectedStore :showOpenTime="true" />
    <div class="d-flex align-center justify-end mt-1 mb-3">
      <v-btn
        v-if="!$ebsn.meta(config, 'categoryblocktype_PreferredStore.HIDE_LINK')"
        color="primary"
        depressed
        text
        rounded
        small
        class="show-all-btn"
        :to="{ name: 'Leaflet' }"
        >{{ $ebsn.meta(config, "categoryblocktype_PreferredStore.BUTTON_TEXT")
        }}<v-icon x-small right>$arrowForward</v-icon></v-btn
      >
    </div>
  </div>
</template>
<style lang="scss">
.preferred-store {
  .show-all-btn {
    .v-btn__content {
      font-size: 14px;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import SelectedStore from "../leaflet/SelectedStore.vue";

export default {
  components: { SelectedStore },
  name: "PreferredStore",
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  }
};
</script>
