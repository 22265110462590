var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      key: _vm.componentKey,
      ref: "productCard",
      staticClass: "product-card",
      class: _vm.type,
      attrs: {
        id: `product-card-${_vm.product.giftCertificateId}`,
        elevation: "2"
      },
      on: { click: _vm.openDetails }
    },
    [
      _c("div", { staticClass: "top-part" }, [
        _c("div", { staticClass: "corner-icon-wrap" }, [
          _c("img", {
            attrs: { src: "/img_layout/thumbs-up-light.svg", alt: "thumbs-up" }
          })
        ]),
        _c("div", { staticClass: "d-flex" }, [
          _c("img", {
            staticClass: "product-img",
            attrs: {
              src: _vm.product.categoryProposal.img,
              onerror: "this.onerror=null;this.src='/no-icon.png'",
              alt: _vm.product.categoryProposal.imgAlt,
              title: _vm.product.categoryProposal.imgAlt
            }
          })
        ]),
        _c("div", { staticClass: "product-detis" }, [
          _c("div", { staticClass: "product-title" }, [
            _vm._v(_vm._s(_vm.product.name))
          ]),
          _c("div", { staticClass: "type" }, [_vm._v("Sconto")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(" " + _vm._s(_vm.amount) + " "),
            _c("span", [_vm._v(_vm._s(_vm.discountType))])
          ])
        ]),
        _c("div", { staticClass: "info-icons" }, [
          _c("div", [
            _vm.expiring
              ? _c("img", {
                  attrs: { src: "/img_layout/clessidra.svg", alt: "clessidra" }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "amount" }, [
            _vm._v(_vm._s(_vm.minUnits)),
            _c("span", [_vm._v("Pz")])
          ])
        ])
      ]),
      _c("div", { staticClass: "bottom-part" }, [
        _c("div", { staticClass: "product-info" }, [
          _c(
            "div",
            [
              _c("v-icon", { attrs: { color: "white" } }, [_vm._v("$coupon")]),
              _vm._v(
                _vm._s(
                  _vm.$t("coupons.galery.available", {
                    number: _vm.availableRedemptions
                  })
                ) + " "
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-icon", { attrs: { color: "white" } }, [
                _vm._v("$calendar")
              ]),
              _vm._v(_vm._s(_vm.remainingDays))
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "action" },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  rounded: "",
                  block: "",
                  color: "white",
                  large: "",
                  elevation: "0"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.toggleCoupon.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.type == "available"
                      ? _vm.$t("coupons.galery.add")
                      : _vm.$t("coupons.galery.remove")
                  )
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }