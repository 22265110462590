var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", [_vm._v(_vm._s(_vm.data.label))]),
      _c("v-select", {
        attrs: {
          outlined: "",
          items: _vm.data.options
            ? _vm.data.options.map(prop => ({
                text: prop.label,
                value: prop.value
              }))
            : null,
          "menu-props": { maxHeight: "400" },
          label: _vm.data.label,
          multiple: ""
        },
        on: { change: _vm.update },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }