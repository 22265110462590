var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.obj.schema.title
      ? _c("h5", {
          staticClass: "font-weight-bold",
          domProps: { innerHTML: _vm._s(_vm.obj.schema.title) }
        })
      : _vm._e(),
    _vm.obj.schema.title
      ? _c("h6", { domProps: { innerHTML: _vm._s(_vm.obj.schema.subtitle) } })
      : _vm._e(),
    _vm.obj.schema.content
      ? _c("p", { domProps: { innerHTML: _vm._s(_vm.obj.schema.content) } })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }