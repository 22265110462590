var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "number-counter d-flex justify-center align-center" },
    _vm._l(_vm.currentValue, function(digit, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "digit d-flex align-center justify-center ma-1 pa-3 text-center font-weight-bold rounded-md"
        },
        [_vm._v(" " + _vm._s(digit) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }