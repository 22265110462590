var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-column pb-0 pt-12 px-0", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-center justify-space-between w-100 pa-3"
        },
        [
          _c("img", {
            style: { height: "70px" },
            attrs: { src: "/logo/logo.png", alt: "Logo" }
          }),
          _c("h2", {
            staticClass: "default--text",
            domProps: { innerHTML: _vm._s(_vm.$t("resetPassword.title")) }
          }),
          _c("p", {
            staticClass: "text-center",
            domProps: { innerHTML: _vm._s(_vm.$t("resetPassword.subtitle")) }
          })
        ]
      ),
      _c(
        "v-card",
        { staticClass: "elevation-0 px-4" },
        [
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "passwordForm",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Email *",
                              required: "",
                              outlined: "",
                              dense: "",
                              rounded: "",
                              clearable: "",
                              rules: _vm.emailRules
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleResetPasswordRequest.apply(
                                  null,
                                  arguments
                                )
                              }
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-0 mb-sm-4", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "reset-button",
                          attrs: {
                            color: "primary",
                            large: "",
                            rounded: "",
                            block: "",
                            depressed: "",
                            loading: _vm.loading
                          },
                          on: { click: _vm.handleResetPasswordRequest }
                        },
                        [_vm._v(_vm._s(_vm.$t("resetPassword.submit")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-2" },
            [_c("ResponseMessage", { attrs: { response: _vm.response } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }