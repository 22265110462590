var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "timeslot-selector" },
    [
      _c("ebsn-meta", {
        staticClass: "text-center",
        attrs: {
          target: _vm.category,
          path: "category_info.TITLE",
          "default-value": _vm.category.name,
          tag: "h1"
        }
      }),
      _c("ebsn-meta", {
        staticClass: "text-center text-body-2 mt-2",
        attrs: { target: _vm.category, path: "category_info.DESCRIPTION" }
      }),
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1", container: false }
      }),
      _c("CartInfoAddressCard", {
        staticClass: "w-100",
        attrs: {
          shippingAddress: _vm.cart.shippingAddress,
          editable: true,
          isCheckout: true
        },
        on: { edit: _vm.openAddressSelector }
      }),
      _c("h4", { staticClass: "mt-5 mb-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("timeslots.dayLabel")) + " ")
      ]),
      _c(
        "v-tabs",
        {
          staticClass: "days-tabs",
          attrs: {
            "hide-slider": "",
            "center-active": "",
            "show-arrows": "",
            height: 70
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tabs-slider"),
          _vm._l(_vm.days, function(timeslotDay) {
            return _c(
              "v-tab",
              {
                key: timeslotDay.dateIso,
                staticClass: "days-tab rounded-md mr-3",
                on: {
                  click: function($event) {
                    return _vm.selectTimeslotDay(timeslotDay)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "time-div d-flex flex-column align-center justify-space-around"
                  },
                  [
                    _vm.addressId
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "today text-body-1 font-weight-bold"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `eugenio.timeslot.days.${timeslotDay.weekDay}`
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      : _vm.$dayjs().isSame(timeslotDay.dateIso, "day")
                      ? [
                          _c(
                            "span",
                            {
                              staticClass:
                                "day-month text-body-1 font-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$dayjs(timeslotDay.dateIso)
                                      .format("DD MMMM")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("span", { staticClass: "day-week text-body-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$dayjs(timeslotDay.dateIso).format("dddd")
                                ) +
                                " "
                            )
                          ])
                        ]
                      : [
                          _c(
                            "span",
                            {
                              staticClass:
                                "day-month text-body-1 font-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$dayjs(timeslotDay.dateIso)
                                      .format("DD MMMM")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("span", { staticClass: "day-week text-body-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$dayjs(timeslotDay.dateIso).format("dddd")
                                ) +
                                " "
                            )
                          ])
                        ]
                  ],
                  2
                )
              ]
            )
          })
        ],
        2
      ),
      _c("h4", { staticClass: "mt-8 mb-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("timeslots.timeLabel")) + " ")
      ]),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.days, function(timeslotDay) {
          return _c(
            "v-tab-item",
            { key: timeslotDay.dateIso, staticClass: "timeslot-container" },
            [
              timeslotDay &&
              timeslotDay.timeslots &&
              timeslotDay.timeslots.length > 0
                ? _c(
                    "v-row",
                    _vm._l(timeslotDay.timeslots, function(timeslot) {
                      return _c(
                        "v-col",
                        {
                          key: timeslot.timeslotId,
                          attrs: {
                            cols: "6",
                            sm: "4",
                            md: _vm.isNotPopup ? 3 : 4,
                            lg: _vm.isNotPopup ? 2 : 4
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "timeslot-card rounded-md",
                              class: [
                                timeslot.status,
                                { selected: timeslot.selected }
                              ],
                              attrs: { outlined: "", disabled: _vm.disabled },
                              on: {
                                click: function($event) {
                                  return _vm.selectTimeslot(timeslot)
                                }
                              }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "justify-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(timeslot.beginTime) +
                                      " - " +
                                      _vm._s(timeslot.endTime) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-card-subtitle",
                                { staticClass: "text-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          `timeslots.availability.${timeslot.status}`
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "p",
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: "error", outlined: "" } },
                        [_vm._v(_vm._s(_vm.$t("timeslots.noSlots")))]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        }),
        1
      ),
      _c("h4", [_vm._v("Legenda")]),
      _c(
        "v-row",
        { staticClass: "legend" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "timeslot-card empty", attrs: { outlined: "" } },
                [
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("timeslots.availability.empty")) + " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "timeslot-card intermediate",
                  attrs: { outlined: "" }
                },
                [
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("timeslots.availability.intermediate")) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "timeslot-card full", attrs: { outlined: "" } },
                [
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("timeslots.availability.full")) + " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "timeslot-card selected",
                  attrs: { outlined: "" }
                },
                [
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("timeslots.availability.selected")) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2", container: false }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }