<template>
  <h2 class="my-0 default--text">
    Ciao <span v-if="user && user.firstName">{{ user.firstName }}!</span>
  </h2>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "WelcomeText",
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  }
};
</script>
