var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "primary--text",
      attrs: {
        rounded: "",
        block: "",
        color: "white",
        "x-large": "",
        depressed: ""
      },
      on: {
        click: function($event) {
          return _vm.doLogin()
        }
      }
    },
    [_vm._v(_vm._s(_vm.label))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }