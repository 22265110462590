var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "product-slider-card",
      attrs: { elevation: "0", flat: "", to: _vm.link },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleLink.apply(null, arguments)
        }
      }
    },
    [
      _c("v-img", {
        staticClass: "product-slider-card-image",
        attrs: { src: _vm.img, alt: "Immagine categoria" }
      }),
      _vm.text
        ? _c("v-card-text", { domProps: { innerHTML: _vm._s(_vm.text) } })
        : _vm._e(),
      _c("v-spacer"),
      _vm.link
        ? _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    to: _vm.link,
                    color: "secondary",
                    block: "",
                    depressed: ""
                  }
                },
                [_vm._v(_vm._s(_vm.linkLabel) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }