var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isCordova &&
    _vm.config.proposals &&
    _vm.config.proposals.length > 0
    ? _c(
        "div",
        { staticClass: "tutorial" },
        [
          _c(
            "v-dialog",
            {
              staticClass: "tutorial-dialog",
              attrs: {
                fullscreen: _vm.$vuetify.breakpoint.mobile,
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.showTutorial,
                callback: function($$v) {
                  _vm.showTutorial = $$v
                },
                expression: "showTutorial"
              }
            },
            [
              _c("ProposalTutorialSlider", {
                attrs: { proposals: _vm.carousel },
                on: {
                  skipTutorial: _vm.closeTutorial,
                  openLogin: _vm.closeAndOpenLogin
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }