var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expense-progress-wraper" }, [
    _c(
      "div",
      { staticClass: "progress-pointers" },
      _vm._l(_vm.segments, function(segment, index) {
        return _c(
          "div",
          {
            key: "progress-pointer-" + index,
            staticClass: "progress-pointer",
            class: { inactive: _vm.points < segment.expense },
            style: { left: 100 * ((index + 1) / _vm.segments.length) + "%" }
          },
          [
            _c("div", { staticClass: "outer-circle outer-circle-theme" }, [
              _vm._v(" " + _vm._s(segment.name) + " ")
            ])
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "progress-dividers" },
      _vm._l(_vm.segments, function(segment, index) {
        return _c(
          "div",
          {
            key: "progress-divider-" + index,
            staticClass: "progress-divider progress-divider-theme",
            class: { inactive: _vm.points < segment.expense },
            style: { left: 100 * ((index + 1) / _vm.segments.length) + "%" }
          },
          [_c("div")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "progress-container" }, [
      _c("div", {
        staticClass: "progress-bar progress-bar-theme",
        style: { width: _vm.percentage + "%" }
      })
    ]),
    _c(
      "div",
      { staticClass: "progress-values progress-values-theme mb-12" },
      _vm._l(_vm.segments, function(segment, index) {
        return _c(
          "div",
          {
            key: "progress-value-" + index,
            class: { inactive: _vm.points < segment.expense },
            style: { left: 100 * ((index + 1) / _vm.segments.length) + "%" }
          },
          [
            _vm._v(" " + _vm._s(segment.expense) + "€ "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("coupons.discounts.onSpecificSector")))
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }