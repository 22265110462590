<template>
  <v-container
    fluid
    fill-height
    class="active-coupon-detail pb-0 pt-12 px-0"
    :class="customClass"
    ref="contentToPrint"
    id="contentToPrint"
  >
    <v-layout>
      <v-flex
        xs12
        class="d-flex flex-column align-center justify-space-between"
      >
        <div class="d-flex flex-column justify-center text-center px-2">
          <h2 v-if="type == 'welcomeCoupon'">
            {{ formatedDesc }}
          </h2>
          <h2 v-else-if="type == 'adventCalendarDiscount'" class="advent-calendar-discount">
            {{ coupon.product.name }}
          </h2>
            <h2 v-else :class="type == 'activeCashbackDiscount'?'hcolor':''">
              <span
                v-if="
                  type == 'activeDiscount' || type == 'activeCashbackDiscount'
                "
                >{{ $t("coupons.discounts.discountCoupon") }} </span
              >{{ coupon.product.name }}
              <span class="small-text"
                v-if="
                  type == 'activeCashbackDiscount'
                "
                >{{ $t("coupons.discounts.onSpecificSectorSubheader") }}</span>
            </h2>
         
          <barcode
            :value="coupon.barCode"
            format="EAN13"
            :displayValue="false"
            class="align-self-center"
            height="80px"
            flat
          />
          <div class="text-body-0">
            {{ $t("coupons.discounts.numericalCode") }}
          </div>
          <h1 class="default--text mt-0">{{ coupon.barCode }}</h1>
          <div v-if="type=='activeCashbackDiscount'" class="mb-7">
            {{ $t("coupons.discounts.onSpecificSector2") }}
            <br>
            {{
              $t("coupons.discounts.valid", {
                startDate: coupon.startRetreat,
                endDate: coupon.endRetreat
              })
            }}
          </div>
          <div
            class="text-body-0 px-5"
            v-if="couponDetails"
            v-html="
              couponDetails.metaData.product_long_description_active.DESCRIPTION
            "
          ></div>
          <ol class="mt-3 text-body-3 disclaimer-messages">
            <li
              v-for="(disclaimer, index) in disclaimerMessages"
              :key="'disclaimer' + index"
            >
              {{ disclaimer }}
            </li>
          </ol>
        </div>
        <div
          class="bottom-section d-flex flex-column align-center grey lighten-1 text-center px-2 w-100 py-6 mt-3"
        >
          <img
            :src="coupon.product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="coupon-img rounded-lg"
            :alt="
              'Coupon-' +
                (type == 'activeDiscount' ? coupon.barCode : coupon.name)
            "
          />
          <div
            @click="print"
            class="pt-2 pb-4 text-body-0 default--text text-decoration-underline"
            data-html2canvas-ignore="true"
          >
            <v-icon class="mr-2" color="default">$download</v-icon
            ><span>{{ $t("coupons.discounts.downloadYourVoucher") }}</span>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.active-coupon-detail {
  text-align: center;
  .coupon-img {
    max-width: 200px;
    width: calc(100vw - 20px);
  }
  .disclaimer-messages {
    text-align: left;
  }
  .bottom-section {
    position: sticky;
    bottom: 0;
  }
  h2 {
    line-height: 30px;
  }
  .small-text{
    display: block; /* Ensures it appears on a new line */
    font-size: 65%;
  }
  .advent-calendar-discount{
    color:#e72c33;
  }
}
</style>

<script>
//import CouponsService from "@/service/couponsService";
import html2pdf from "html2pdf.js";
import downloadDocumentMixin from "~/mixins/downloadDocument";
import socialsharing from "@/mixins/socialsharing";
import matomoService from "@/service/analytics/matomoService";

export default {
  name: "ActiveCouponDetail",
  mixins: [downloadDocumentMixin, socialsharing],
  props: {
    coupon: { type: Object, required: true },
    type: { type: String, required: true },
    customClass: { type: String, default: "" }
  },
  components: {},
  data() {
    return {
      couponDetails: null,
      disclaimerMessages: []
    };
  },
  computed: {
    formatedDesc() {
      return this.coupon.product.description.replace(/\|/g, "\n");
    }
  },
  mounted(){
    matomoService.trackPageView("/couponDetails");
  },
  methods: {
    async handleReceiptShare(dataurl, fileName) {
      console.log("try to share coupon");
      this.shareLoading = true;
      try {
        window.plugins.socialsharing.share(null, fileName, dataurl, null);
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        this.shareLoading = false;
      }
    },
    print() {
      console.log("printing");
      const element = document.getElementById("contentToPrint");
      const title = "Sconto-" + this.coupon.product.name;
      const opt = {
        margin: 8,
        filename: title,
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
        image: { type: "jpeg", quality: 0.9 }
      };
      element.classList.add("only-on-pdf");

      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";

      console.log(platform);
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          console.log("ciaooo");
          element.classList.remove("only-on-pdf");
          //console.log("platform", this.device.platform);
          if (this.$platform_is_cordova && platform === "ios") {
            console.log("try to share coupon on ios");
            const pdfDataUrl = pdf.output("dataurlstring");
            console.log(pdfDataUrl);
            this.handleReceiptShare(pdfDataUrl, title);
          } else {
            const pdfDataUrl = pdf.output("bloburl");
            this.handleReceiptDownload(pdfDataUrl, title);
          }
        });
    }
  },
  async created() {
   
    let d;
    console.log(this.type);
    switch (this.type) {
      case "welcomeCoupon":
        d = "coupons.discounts.welcomeDiscountsDisclaimer";
        break;
      case "activeCashbackDiscount":
        d = "coupons.discounts.cashbackDiscountsDisclaimer";
        break;
      default:
        d = "coupons.discounts.disclaimer";
        break;
    }
    this.disclaimerMessages = this.geti18nObject(d);
  }
};
</script>
