var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "static-text",
      style: _vm.style,
      attrs: { id: `static-text-${_vm.config.categoryBlockId}` }
    },
    [
      _c(
        "div",
        { class: { container: _vm.container }, on: { click: _vm.clicked } },
        [
          _c("ebsn-meta", {
            attrs: {
              target: _vm.config,
              path: "categoryblocktype_StaticText.TITLE",
              tag: "h2"
            }
          }),
          _vm.template
            ? _c("v-runtime-template", { attrs: { template: _vm.template } })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }