var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProductListSlider", {
    directives: [
      {
        name: "intersect",
        rawName: "v-intersect.once",
        value: _vm.handleView,
        expression: "handleView",
        modifiers: { once: true }
      }
    ],
    attrs: {
      config: _vm.config,
      products: _vm.products,
      categoryBlockName: "ProductSlider",
      showMore: _vm.showMore
    },
    on: { selectProduct: _vm.handleClick, reload: _vm.reload }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }