var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "mr-2",
      class: _vm.field.element_class,
      attrs: { color: "primary", large: "", type: "submit" },
      on: { click: _vm.onSubmit },
      model: {
        value: _vm.field.value,
        callback: function($$v) {
          _vm.$set(_vm.field, "value", $$v)
        },
        expression: "field.value"
      }
    },
    [_vm._v(" " + _vm._s(_vm.field.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }