var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "proposal-tutorial-slider swiper-container-wrapper" },
    [
      _vm.proposals
        ? _c(
            "swiper",
            { ref: "swiperRef", attrs: { options: _vm.swiperOption } },
            [
              _vm._l(_vm.proposals, function(proposal) {
                return _c(
                  "swiper-slide",
                  { key: proposal.id },
                  [
                    _c("ProposalTutorialSlide", {
                      attrs: { proposal: proposal },
                      on: {
                        skipTutorial: _vm.skipTutorial,
                        openLogin: _vm.openLogin
                      }
                    })
                  ],
                  1
                )
              }),
              _vm.proposals.length > 0
                ? _c("div", {
                    staticClass: "swiper-pagination swiper-pagination-tutorial",
                    attrs: { slot: "pagination" },
                    slot: "pagination"
                  })
                : _vm._e(),
              _c("div", {
                staticClass: "swiper-button-next",
                attrs: { slot: "button-next" },
                slot: "button-next"
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }