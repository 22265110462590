var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.field.desc_text
        ? _c("small", { domProps: { innerHTML: _vm._s(_vm.field.desc_text) } })
        : _vm._e(),
      _c("v-checkbox", {
        attrs: {
          rules: _vm.rules,
          value: 0,
          "true-value": 1,
          "false-value": 0,
          color: _vm.color
        },
        on: {
          change: function($event) {
            return _vm.change(_vm.value)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.field.label) } })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }