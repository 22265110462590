<template>
  <div
    class="d-flex flex-column align-center registration-completed-container mt-6"
    style="text-align: center;"
  >
  <img src="/logo/logo.png" alt="Logo" :style="{ height: '70px' }" />

    <v-row class="first-part">
      <v-col cols="12">
        <div class="default--text font-weight-semibold title-text pt-5">
          {{ $t("register.registrationCompleted.title") }}
        </div>
        <div class="sub-text">
          {{ $t("register.registrationCompleted.subtitle") }}
        </div>
      </v-col>
    </v-row>
    <v-col cols="12">
      <div class="mail-click">
        {{ $t("register.registrationCompleted.linkEmail") }}
      </div>
    </v-col>
    <v-col cols="12">
    </v-col>
    <v-col class="pt-3" style="width: 80%;">
      <v-btn @click="goToLogin" rounded block x-large color="primary">{{
        $t("register.registrationCompleted.goToLogin")
      }}</v-btn>
    </v-col>
    <div class="divider">
      <v-divider></v-divider>
    </div>
    <v-row class="pt-2 second-part" style="width: 80%;">
      <v-col cols="12">
        <div class="didnt-receive-mail font-weight-semibold">
          {{ $t("register.registrationCompleted.didntReceiveMail") }}
          <br>
          {{ $t("register.registrationCompleted.checkSpam") }}
        </div>
        <div class="didnt-receive-mail font-weight-semibold">
       
        </div>
      </v-col>
      <v-col>
        <v-btn
          :disabled="disableSendEmail"
          @click="resendRegistrationEmail"
          rounded
          outlined
          block
          x-large
          color="primary"
          >{{ $t("register.registrationCompleted.reSendEmail") }}</v-btn
        >
      </v-col>
      <v-col class="pt-10" cols="12">
        <div class="font-weight-semibold need-help">
          {{ $t("register.registrationCompleted.needHelp") }}
        </div>
        <div class="font-weight-semibold text-decoration-underline contact" @click="goToContactForm()">
          {{ $t("register.registrationCompleted.contactUs") }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss"></style>
<script>
import categoryMixins from "~/mixins/category";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
export default {
  name: "RegistrationCompleted",

  data() {
    return {
      mail: "",
      socialLogin: null,
      agreement: null,
      disableSendEmail:false,
    };
  },
  props: { email: { type: String, required: true } },
  mixins: [categoryMixins],
  methods: {
    async goToHome() {
      this.$router.push({
        name: "Home",
        path: "/"
      });
    },
    async goToLogin() {
      this.$emit("submit",true);
      this.$router.push({
        name: "login",
        path: "/login"
      });
    },
    async goToContactForm() {
      this.$emit("submit",true);
      this.$router.push(`/gala-world/contact-form`);
    },
    async resendRegistrationEmail(){
      AbbondanzaRegistrationService.resendRegistrationEmailWithParams(this.email);
      this.disableSendEmail = true;
      setTimeout(()=>{
        this.disableSendEmail= false;
      },30000)
    }
  },
  created() {
    this.mail = this.$route.params.mail;
    this.socialLogin = this.$route.params.socialLogin;
    this.agreement = this.$route.params.agreement;
  }
};
</script>
