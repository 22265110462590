var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "intersect",
          rawName: "v-intersect.once",
          value: _vm.handleView,
          expression: "handleView",
          modifiers: { once: true }
        }
      ],
      staticClass: "proposal-card-horizontal-responsive",
      style: _vm.getStyle("BACKGROUND_COLOR", "TEXT_COLOR"),
      attrs: { rounded: "md", href: _vm.link, outlined: "" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleLink.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              style: _vm.getStyle("BG_ICON_COLOR"),
              attrs: { cols: "12", md: "6" }
            },
            [
              _c("v-img", {
                staticClass: "image-card",
                attrs: {
                  src: _vm.imgSrc,
                  alt: _vm.proposal.imgAlt,
                  title: _vm.proposal.imgDescription,
                  contain: _vm.$ebsn.meta(
                    _vm.proposal,
                    "metaData.category_proposal_type.IMAGE_CONTAIN",
                    false
                  )
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass:
                "d-flex flex-column justify-center align-center align-md-start",
              attrs: { cols: "12", md: "6" }
            },
            [
              _vm.proposal.descr
                ? _c("v-card-title", { staticClass: "secondary--text" }, [
                    _vm._v(" " + _vm._s(_vm.proposal.descr) + " ")
                  ])
                : _vm._e(),
              _vm.proposal.content
                ? _c("v-card-text", {
                    staticClass: "secondary--text",
                    domProps: { innerHTML: _vm._s(_vm.proposal.content) }
                  })
                : _vm._e(),
              _vm.link &&
              _vm.$ebsn.meta(_vm.proposal, "category_proposal_type.SHOW_BTN")
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          style: _vm.getStyle("TEXT_COLOR", "BACKGROUND_COLOR"),
                          attrs: {
                            href: _vm.link,
                            depressed: "",
                            large: "",
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleLink.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$ebsn.meta(
                                  _vm.proposal,
                                  "category_proposal_type.BTN_TEXT",
                                  "Visualizza"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }