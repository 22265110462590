var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    staticClass: "leaflet-iframe rounded-sm",
    attrs: {
      src: _vm.decodeVideoEmbedURL(_vm.videoURL),
      frameborder: "0",
      allow: "accelerometer; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen: ""
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }