var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "cart-info-card h-100 px-3 py-3",
      attrs: { flat: "", tile: "" },
      on: { click: _vm.edit }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center h-100" },
        [
          _c("div", { staticClass: "d-flex flex-row align-center" }, [
            _vm.icon
              ? _c(
                  "div",
                  { staticClass: "white pa-2 mr-2 rounded-sm icon-info-card" },
                  [
                    _c("v-icon", { attrs: { color: "secondary" } }, [
                      _vm._v(_vm._s(_vm.icon))
                    ])
                  ],
                  1
                )
              : _vm.imgSrc
              ? _c(
                  "div",
                  { staticClass: "img-container white pa-1 rounded-sm" },
                  [
                    _c("v-img", {
                      staticClass: "img-info-card",
                      attrs: {
                        src: _vm.imgSrc,
                        contain: "",
                        alt: "Immagine servizio",
                        height: "33",
                        width: "33"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "text-body-2" }, [
              _c("div", { staticClass: "text font-weight-bold" }, [
                _vm._v(_vm._s(_vm.text))
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.infoValue))
              ])
            ])
          ]),
          _vm.editable
            ? _c(
                "v-icon",
                {
                  staticClass: "edit",
                  attrs: { small: "", color: "default", right: "" }
                },
                [_vm._v(" $edit ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }