var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [{ name: "ripple", rawName: "v-ripple" }],
      staticClass: "preferred-store-card d-flex align-center",
      on: {
        click: function($event) {
          _vm.selectedStore
            ? _vm.$router.push({ path: "/punti-vendita/" + _vm.selectedStore })
            : _vm.$router.push({ path: "/punti-vendita" })
        }
      }
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "flex-grow-1 d-flex flex-column" }, [
        _vm.warehouse
          ? _c("div", [
              !_vm.showOpenTime
                ? _c("div", { staticClass: "text-uppercase default--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("leaflet.preferredShop")) + " ")
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "store-name primary--text font-weight-semibold text-body-0"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.warehouse.name) +
                      " " +
                      _vm._s(_vm.warehouse.address.city) +
                      " "
                  )
                ]
              ),
              _vm.showOpenTime
                ? _c("div", [
                    _c(
                      "span",
                      { staticClass: "primary--text font-weight-semibold" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.calculateTime.open
                              ? _vm.$t("warehouse.open")
                              : _vm.$t("warehouse.closed")
                          ) + " "
                        )
                      ]
                    ),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.calculateTime.message) }
                    })
                  ])
                : _vm._e()
            ])
          : !_vm.loading
          ? _c(
              "div",
              {
                staticClass:
                  "store-name primary--text font-weight-semibold text-body-0"
              },
              [_vm._v(" " + _vm._s(_vm.$t("leaflet.noPreferredShop")) + " ")]
            )
          : _vm._e()
      ]),
      _vm.selectedStore
        ? _c(
            "div",
            [
              _c("v-icon", { staticClass: "default--text" }, [
                _vm._v("$chevronRight")
              ])
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo-img" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: "/logo/logo_mobile.png", alt: "Logo" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }