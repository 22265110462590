var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "title-row d-flex justify-space-between align-start" },
    [
      _c("ebsn-meta", {
        class: "category-block-title",
        attrs: {
          target: _vm.props.config,
          path: `categoryblocktype_${_vm.props.categoryBlockName}.TITLE`,
          tag: "h2",
          cssClass: "slider-title category-block-title"
        }
      }),
      _vm.props.showMore &&
      !_vm.parent.$ebsn.meta(
        _vm.props.config,
        "categoryblocktype_" + _vm.props.categoryBlockName + ".HIDE_LINK"
      )
        ? _c(
            "v-btn",
            {
              staticClass: "align-self-end",
              attrs: {
                color: "primary",
                depressed: "",
                text: "",
                rounded: "",
                to: _vm.$options.calcLinkToGo(
                  _vm.props.config,
                  _vm.props.categoryBlockName,
                  _vm.parent.$ebsn.meta
                )
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.parent.$ebsn.meta(
                      _vm.props.config,
                      "categoryblocktype_" +
                        _vm.props.categoryBlockName +
                        ".BUTTON_TEXT",
                      _vm.parent.$t("categoryBlock.productListSlider.showAll")
                    )
                  ) +
                  " "
              ),
              _c("v-icon", { attrs: { small: "", right: "" } }, [
                _vm._v("$arrowForward")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }