var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.snackbars, function(snackbar, idx) {
        return _c(
          "v-snackbar",
          _vm._b(
            {
              key: snackbar.key,
              ref: "v-snackbars-" + _vm.identifier,
              refInFor: true,
              class:
                "v-snackbars v-snackbars-" +
                _vm.identifier +
                "-" +
                snackbar.key,
              staticStyle: { position: "fixed", "z-index": "99999" },
              attrs: {
                app: "",
                absolute: "",
                top: snackbar.top,
                bottom: snackbar.bottom,
                left: snackbar.left,
                right: snackbar.right,
                color: snackbar.color,
                transition: snackbar.transition,
                timeout: -1
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _vm._t("default", function() {
                          return [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(snackbar.message) }
                            })
                          ]
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "action",
                    fn: function() {
                      return [
                        _vm._t(
                          "action",
                          function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeMessage(
                                        snackbar.key,
                                        true
                                      )
                                    }
                                  }
                                },
                                [_vm._v("X")]
                              )
                            ]
                          },
                          {
                            close: () => _vm.removeMessage(snackbar.key, true),
                            id: snackbar.key,
                            index: idx
                          }
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              ),
              model: {
                value: snackbar.show,
                callback: function($$v) {
                  _vm.$set(snackbar, "show", $$v)
                },
                expression: "snackbar.show"
              }
            },
            "v-snackbar",
            _vm.$attrs,
            false
          )
        )
      }),
      _vm._l(_vm.keys, function(key, idx) {
        return _c("css-style", { key: key + idx }, [
          _vm._v(
            " .v-snackbars.v-snackbars-" +
              _vm._s(_vm.identifier) +
              "-" +
              _vm._s(key) +
              " .v-snack__wrapper { transition: " +
              _vm._s(_vm.topOrBottom[key]) +
              " 500ms; " +
              _vm._s(_vm.topOrBottom[key]) +
              ": 0; } .v-snackbars.v-snackbars-" +
              _vm._s(_vm.identifier) +
              "-" +
              _vm._s(key) +
              " > .v-snack__wrapper { " +
              _vm._s(_vm.topOrBottom[key]) +
              ": " +
              _vm._s(_vm.calcDistance(key)) +
              "px; min-height: 36px;} "
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }