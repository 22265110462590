var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-3 pt-6 px-3", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.title))]),
          _c("span", [_vm._v(_vm._s(_vm.message))]),
          _c(
            "v-btn",
            {
              staticClass: "mt-3",
              attrs: { color: "primary", block: "", rounded: "" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(true)
                }
              }
            },
            [_vm._v(_vm._s(_vm.button_yes))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mt-3",
              attrs: { color: "primary", block: "", rounded: "", outlined: "" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(_vm._s(_vm.button_no))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }