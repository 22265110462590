var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pb-2 pt-12 px-2 galery-coupon-detail",
      attrs: { fluid: "" }
    },
    [
      _vm.coupon.metaData.voucher_info.key
        ? _c("ProductCardBlackFriday", {
            attrs: { product: _vm.coupon, type: _vm.type },
            on: { reload: _vm.reload }
          })
        : _c("ProductCard", {
            attrs: { product: _vm.coupon, type: _vm.type },
            on: { reload: _vm.reload }
          }),
      _c("div", [
        _c("div", { staticClass: "quick-info my-6" }, [
          _c(
            "div",
            [
              _c("v-icon", { attrs: { color: "default" } }, [
                _vm._v("$calendar")
              ]),
              _vm._v(
                _vm._s(
                  _vm.$t("coupons.galery.endDate", {
                    number: _vm.$dayjs(_vm.coupon.endDate).format("DD/MM/YYYY")
                  })
                ) + " "
              )
            ],
            1
          )
        ]),
        _c("div", {
          staticClass: "font-weight-semibold mb-3 text-body-0 pre-wrap",
          domProps: { innerHTML: _vm._s(_vm.formatedName) }
        }),
        _c("div", {
          staticClass: "pre-wrap",
          domProps: { innerHTML: _vm._s(_vm.formatedDesc) }
        }),
        _c(
          "ol",
          { staticClass: "mt-3 text-body-3 text-start" },
          _vm._l(_vm.disclaimerMessages, function(disclaimer, index) {
            return _c("li", { key: "disclaimer" + index }, [
              _vm._v(" " + _vm._s(_vm.$t(disclaimer)) + " ")
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }