var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ebsn-meta", {
        class: "category-block-title",
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_BannerSlider.TITLE",
          tag: "h2"
        }
      }),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_BannerSlider.DESCRIPTION",
          tag: "div"
        }
      }),
      _c(
        "div",
        { staticClass: "slider banner-slider mx-n2" },
        [
          _c(
            "swiper",
            {
              ref: "swiperRef",
              staticClass: "px-2",
              attrs: { options: _vm.swiperOption }
            },
            _vm._l(_vm.config.proposals, function(proposal) {
              return _c(
                "swiper-slide",
                { key: proposal.id },
                [
                  _c(_vm.getProposalModelName(proposal), {
                    tag: "component",
                    attrs: {
                      proposal: proposal,
                      position: _vm.config.templateBlock.codInt
                    }
                  })
                ],
                1
              )
            }),
            1
          ),
          _vm.$ebsn.meta(
            _vm.config,
            "categoryblocktype_BannerSlider.SHOW_BULLETS",
            true
          ) && _vm.config.proposals.length > 1
            ? _c("div", {
                staticClass: "swiper-pagination",
                attrs: { id: `banner-pagination-${_vm.config.categoryBlockId}` }
              })
            : _vm._e(),
          _vm.$ebsn.meta(
            _vm.config,
            "categoryblocktype_BannerSlider.SHOW_ARROWS",
            false
          ) && _vm.config.proposals.length > 1
            ? _c("div", {
                staticClass: "swiper-button-prev",
                attrs: {
                  id: `banner-slider-prev-${_vm.config.categoryBlockId}`
                }
              })
            : _vm._e(),
          _vm.$ebsn.meta(
            _vm.config,
            "categoryblocktype_BannerSlider.SHOW_ARROWS",
            false
          ) && _vm.config.proposals.length > 1
            ? _c("div", {
                staticClass: "swiper-button-next",
                attrs: {
                  id: `banner-slider-next-${_vm.config.categoryBlockId}`
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }