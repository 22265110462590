var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("h2", { staticClass: "my-0 default--text" }, [
    _vm._v(" Ciao "),
    _vm.user && _vm.user.firstName
      ? _c("span", [_vm._v(_vm._s(_vm.user.firstName) + "!")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }