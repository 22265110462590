<template>
  <v-container fluid class="pb-3 pt-6 px-3">
    <div class="text-center">
      <h3>{{ $t("noActiveCard.title") }}</h3>
      <div class="text-body-0">{{ message }}</div>
      <div class="text-body-0 mb-2">
        {{ $t("noActiveCard.addNewCard") }}
      </div>
      <v-form
        class="d-flex flex-wrap"
        ref="form"
        @submit.prevent
        v-model="isValid"
      >
        <v-row no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              :label="
                `${$t('register.card.step-0.placeholder.codiceCartaFedeltà')} *`
              "
              :rules="[requiredRules()]"
              class="mt-2"
              required
              outlined
              rounded
              dense
              type="number"
              v-model="cardNumber"
            />
          </v-col>
          <ResponseMessage :response="response" class="mt-3" />
          <div
            class="w-100 d-flex flex-column flex-md-row justify-sm-space-between justify-md-start"
          >
            <v-col md="5" class="px-0">
              <v-btn
                depressed
                color="primary"
                block
                rounded
                large
                :loading="loading"
                @click="submit"
                >{{ $t("register.card.step-0.btnConfirm") }}</v-btn
              ></v-col
            >
          </div>
        </v-row>
      </v-form>
      <v-btn
        depressed
        color="primary"
        block
        rounded
        large
        outlined
        :loading="loading"
        class="mt-8"
        @click="requestNewCard"
        >{{ $t("noActiveCard.requestNewCard") }}</v-btn
      >
      <div class="py-1">{{ $t("noActiveCard.goToStore") }}</div>
    </div>
  </v-container>
</template>
<style lang="scss"></style>

<script>
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import ResponseMessage from "@/components/ResponseMessage";
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "CardNotActive",
  props: { message: { type: String, required: true } },
  components: { ResponseMessage },
  data() {
    return {
      isValid: false,
      requiredRules: requiredValue,
      cardNumber: null,
      response: {},
      loading: false
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        AbbondanzaRegistrationService.verifyCard(this.cardNumber).then(data => {
          this.response = data.response;
          if (data.response.status == 0) {
            this.$emit("submit", true);
            global.EventBus.$emit("login");
          }
        });
      }
    },
    async requestNewCard() {
      // this.$emit("submit", true);
      let title = this.$t("noActiveCard.activateNewCard") + " ";
      let res = await this.$dialog.confirm({
        text: `<h2 class='primary--text text-center'>${title}</h2>`,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: this.$t("common.no")
          },
          true: {
            rounded: true,
            text: this.$t("common.yes")
          }
        }
      });
      if (res) {
        AbbondanzaRegistrationService.createVirtualCard().then(data => {
          this.response = data.response;
          if (data.response.status == 0) {
            this.$emit("submit", true);
            this.$emit("submit", true);
            global.EventBus.$emit("login");
          }
        });
      }
    }
  }
};
</script>
