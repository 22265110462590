var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-0 pt-12 px-0", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between",
              attrs: { xs12: "" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-center justify-space-between w-100"
                },
                [
                  _c("img", {
                    style: { height: "70px" },
                    attrs: { src: "/logo/logo.png", alt: "Logo" }
                  }),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "w-100",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.handleSubmit.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "default--text text-center mt-6 mb-0"
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("login.title")) + " ")]
                          ),
                          _c("p", { staticClass: "mb-0 text-center px-4" }, [
                            _vm._v(" " + _vm._s(_vm.$t("login.subtitle")) + " ")
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-card-text",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  color: "primary",
                                  "error-messages": _vm.errors,
                                  type: "email",
                                  label: _vm.$t("login.email"),
                                  autocomplete: "off",
                                  rules: _vm.emailRules,
                                  required: "",
                                  dense: "",
                                  outlined: "",
                                  rounded: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  color: "primary",
                                  label: _vm.$t("login.password"),
                                  autocomplete: "off",
                                  "append-icon": _vm.showPassword
                                    ? "$eye"
                                    : "$eyeOff",
                                  type: _vm.showPassword ? "text" : "password",
                                  rules: _vm.passwordRules,
                                  required: "",
                                  dense: "",
                                  outlined: "",
                                  rounded: "",
                                  clearable: ""
                                },
                                on: { "click:append": _vm.toggleShowPassword },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loading,
                                    type: "submit",
                                    color: "primary",
                                    large: "",
                                    block: "",
                                    depressed: "",
                                    rounded: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("login.buttonLogin")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-6",
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "end"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "forgot-password text-decoration-underline text-caption default--text mt-6",
                                      on: { click: _vm.openResetPasswordDialog }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("login.forgotPassword")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("ResponseMessage", {
                                staticClass: "mt-3",
                                attrs: { response: _vm.response }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.enableRegistration
                ? _c(
                    "div",
                    {
                      staticClass:
                        "no-account-section d-flex secondary flex-column default--text pa-4 pb-12 w-100 white--text"
                    },
                    [
                      _c("h4", { staticClass: "mb-0" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("login.messageRegister")) + " "
                        )
                      ]),
                      _c("p", { staticClass: "px-5 font-weight-light" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("login.messageRegisterInfo")) +
                            " "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            depressed: "",
                            rounded: "",
                            color: "white"
                          },
                          on: { click: _vm.openRegistrationDialog }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("login.buttonRegister")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }