<template>
  <v-container fluid class="pb-2 pt-12 px-2 galery-coupon-detail">
    <ProductCardBlackFriday v-if="coupon.metaData.voucher_info.key" :product="coupon" :type="type" @reload="reload" />
    <ProductCard v-else :product="coupon" :type="type" @reload="reload" />

    <div>
      <div class="quick-info my-6">
        <!-- <div>
          <v-icon color="default">$coupon</v-icon
          >{{
            $t("coupons.galery.available", {
              number: coupon.perUserLimit
            })
          }}
        </div> -->
        <div>
          <v-icon color="default">$calendar</v-icon
          >{{
            $t("coupons.galery.endDate", {
              number: $dayjs(coupon.endDate).format("DD/MM/YYYY")
            })
          }}
        </div>
      </div>
      <div
        v-html="formatedName"
        class="font-weight-semibold mb-3 text-body-0 pre-wrap"
      ></div>
      <div v-html="formatedDesc" class="pre-wrap"></div>
      <ol class="mt-3 text-body-3 text-start">
        <li
          v-for="(disclaimer, index) in disclaimerMessages"
          :key="'disclaimer' + index"
        >
          {{ $t(disclaimer) }}
        </li>
      </ol>
    </div>
  </v-container>
</template>
<style lang="scss">
.galery-coupon-detail {
  .quick-info {
    display: flex;
    div {
      width: 50%;
      .v-icon {
        font-weight: normal;
        font-size: 16px;
        margin-right: 5px;
        width: 20px;
      }
    }
  }
}
</style>

<script>
import ProductCardBlackFriday from "../product/ProductCardBlackFriday.vue";
import ProductCard from "../product/ProductCard.vue";
import matomoService from "@/service/analytics/matomoService";
export default {
  name: "GaleryCouponDetail",
  props: {
    coupon: { type: Object, required: true },
    type: { type: String, default: "available" }
  },
  components: { ProductCardBlackFriday,ProductCard },

  data() {
    return {
      disclaimerMessages: []
    };
  },
  mounted(){
    matomoService.trackPageView("/couponDetails");
  }, 
  computed: {
    formatedName() {
      return this.coupon.name.replace(/\|/g, "\n");
    },
    formatedDesc() {
      return this.coupon.description.replace(/\|/g, "\n");
    }
  },
  methods: {
    reload() {
      this.$emit("submit", true);
    }
  },
  async created() {
    this.disclaimerMessages = this.geti18nObject("coupons.galery.disclaimer");
  }
};
</script>
