var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress-bar-block mx-n2 px-2 py-4 mb-4" },
    [
      _c("TitleRow", {
        attrs: {
          config: _vm.config,
          categoryBlockName: "CalendarBarBlock",
          showMore: true
        }
      }),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_CalendarBarBlock.DESCRIPTION",
          tag: "div"
        }
      }),
      _c("ExpenseCalendarBar", {
        attrs: { calendar: _vm.calendar, segments: _vm.segments }
      }),
      _c("div", { staticClass: "default--text font-weight-regular pt-3" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("calendarBar.weeksCompleted").split(";")[0]) + " "
        ),
        _c("strong", [_vm._v(_vm._s(_vm.completed))]),
        _vm._v(
          " " + _vm._s(_vm.$t("calendarBar.weeksCompleted").split(";")[1]) + " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }