var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      key: _vm.componentKey,
      ref: "productCard",
      staticClass: "product-card-bf",
      class: _vm.type,
      attrs: {
        id: `product-card-${_vm.product.giftCertificateId}`,
        elevation: "2"
      },
      on: { click: _vm.openDetails }
    },
    [
      _c("div", { staticClass: "top-part" }, [
        _c("div", { staticClass: "first-part" }, [
          _vm.selected
            ? _c("div", { staticClass: "corner-icon-wrap" }, [
                _c("img", {
                  attrs: {
                    src: "/img_layout/thumbs-up-black.svg",
                    alt: "thumbs-up"
                  }
                })
              ])
            : _vm._e(),
          _c("div", { staticClass: "d-flex" }, [
            _c("img", {
              staticClass: "product-img",
              attrs: {
                src: _vm.product.categoryProposal.img,
                onerror: "this.onerror=null;this.src='/no-icon.png'",
                alt: _vm.product.categoryProposal.imgAlt,
                title: _vm.product.categoryProposal.imgAlt
              }
            })
          ]),
          _c("div", { staticClass: "product-title" }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.product.name))])
          ])
        ]),
        _c("div", { staticClass: "second-part" }, [
          _c("img", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: "/img_layout/black_friday/black-friday-logo.png" }
          }),
          _c("div", { staticClass: "price-info-1" }, [
            _vm._v(" " + _vm._s(_vm.minUnits)),
            _c("span", { staticStyle: { "font-size": "10px" } }, [_vm._v("Pz")])
          ]),
          _c("div", { staticClass: "price-info-2" }, [
            _vm._v(_vm._s(_vm.initialPrice))
          ]),
          _c("div", { staticClass: "price-info-3" }, [
            _vm._v(_vm._s(_vm.finalPrice))
          ]),
          _c(
            "div",
            {
              staticClass: "price-info-4",
              staticStyle: { "line-height": "1", margin: "0", padding: "0" }
            },
            [_vm._v(" Prezzo Finale con "), _c("br"), _vm._v("Buono Sconto ")]
          ),
          _c("div")
        ])
      ]),
      _vm.selected
        ? _c(
            "div",
            {
              staticClass: "bottom-part-selected",
              staticStyle: { "background-color": "#feee00" }
            },
            [
              _c("div", { staticClass: "product-info-selected" }, [
                _c(
                  "div",
                  [
                    _c("v-icon", { attrs: { color: "black" } }, [
                      _vm._v("$coupon")
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t("coupons.galery.available", {
                          number: _vm.availableRedemptions
                        })
                      ) + " "
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("v-icon", { attrs: { color: "black" } }, [
                      _vm._v("$calendar")
                    ]),
                    _vm._v(_vm._s(_vm.remainingDays))
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "action" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        block: "",
                        color: _vm.toggleColor,
                        large: "",
                        elevation: "0"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleCoupon.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.type == "available"
                            ? _vm.$t("coupons.galery.add")
                            : _vm.$t("coupons.galery.remove")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "bottom-part-available",
              staticStyle: { "background-color": "black" }
            },
            [
              _c("div", { staticClass: "product-info-available" }, [
                _c(
                  "div",
                  [
                    _c("v-icon", { staticStyle: { color: "#feee00" } }, [
                      _vm._v("$coupon")
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t("coupons.galery.available", {
                          number: _vm.availableRedemptions
                        })
                      ) + " "
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("v-icon", { staticStyle: { color: "#feee00" } }, [
                      _vm._v("$calendar")
                    ]),
                    _vm._v(_vm._s(_vm.remainingDays) + " ")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "action" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        block: "",
                        color: _vm.toggleColor,
                        large: "",
                        elevation: "0"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleCoupon.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.type == "available"
                            ? _vm.$t("coupons.galery.add")
                            : _vm.$t("coupons.galery.remove")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }