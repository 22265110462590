import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default{
  init(){
    Vue.use(VueMatomo, {
      host: '//analytics.megadesk.it/',
      siteId: 10
    });
  },
  trackPageView(path,title){
    let _paq = window._paq = window._paq || [];
    let url = process.env.VUE_APP_EBSN_URL; 

    _paq.push(['setCustomUrl', url + (path.startsWith("/"))? path: "/" +path]);
    _paq.push(['setDocumentTitle', title]);
    _paq.push(['trackPageView']);
  }

}


