var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-data" },
    [
      _vm.step == 1
        ? _c(
            "div",
            { staticClass: "text-uppercase font-weight-semibold px-2 mt-6" },
            [_vm._v(" " + _vm._s(_vm.$t("profile.header.personalData")) + " ")]
          )
        : _vm._e(),
      _vm.step == 1
        ? _c("div", { staticClass: "mb-2 text-body-3 px-2" }, [
            _vm._v(" * " + _vm._s(_vm.$t("profile.requiredFields")) + " ")
          ])
        : _vm._e(),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2", attrs: { "no-gutters": "" } },
            [
              _vm.step == 1
                ? _c(
                    "v-container",
                    [
                      _vm.userData.fidelity
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-2",
                                attrs: {
                                  label: `${_vm.$t("profile.cartaFedeltà")}`,
                                  outlined: "",
                                  rounded: "",
                                  dense: "",
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.userData.fidelity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.userData, "fidelity", $$v)
                                  },
                                  expression: "userData.fidelity"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t(
                                "register.card.step-0.placeholder.name"
                              )} *`,
                              rules: [_vm.requiredRules()],
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text",
                              disabled: !!_vm.userData.fidelity
                            },
                            model: {
                              value: _vm.userData.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "firstName", $$v)
                              },
                              expression: "userData.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t(
                                "register.card.step-0.placeholder.surname"
                              )} *`,
                              rules: [_vm.requiredRules()],
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text",
                              disabled: !!_vm.userData.fidelity
                            },
                            model: {
                              value: _vm.userData.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "lastName", $$v)
                              },
                              expression: "userData.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.address.address")}`,
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text"
                            },
                            model: {
                              value: _vm.userData.address,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "address", $$v)
                              },
                              expression: "userData.address"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t(
                                "profile.address.addressNumber"
                              )}`,
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text"
                            },
                            model: {
                              value: _vm.userData.addressNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "addressNumber", $$v)
                              },
                              expression: "userData.addressNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            ref: "cap",
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t(
                                "profile.address.postalcode"
                              )} *`,
                              rules: _vm.capRules,
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text"
                            },
                            on: { input: _vm.getCities },
                            model: {
                              value: _vm.userData.cap,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "cap", $$v)
                              },
                              expression: "userData.cap"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.hasData
                            ? _c("v-text-field", {
                                staticClass: "mt-2",
                                attrs: {
                                  label: `${_vm.$t("profile.address.city")} *`,
                                  rules: [_vm.requiredRules()],
                                  required: "",
                                  outlined: "",
                                  rounded: "",
                                  dense: "",
                                  type: "text",
                                  disabled: "",
                                  loading: _vm.loadingCities
                                },
                                model: {
                                  value: _vm.userData.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.userData, "city", $$v)
                                  },
                                  expression: "userData.city"
                                }
                              })
                            : _c("v-select", {
                                ref: "selectedCity",
                                staticClass: "mt-2",
                                attrs: {
                                  items: _vm.cityList,
                                  "item-text": "city",
                                  "return-object": "",
                                  label: `${_vm.$t("profile.address.city")} *`,
                                  outlined: "",
                                  rounded: "",
                                  dense: "",
                                  disabled:
                                    _vm.isFieldDisabled || !_vm.showCityInput,
                                  rules: [_vm.requiredRules()],
                                  "no-data-text": _vm.$t(
                                    "profile.address.noCityFoundForCap"
                                  ),
                                  loading: _vm.loadingCities
                                },
                                on: { change: _vm.citySelected },
                                model: {
                                  value: _vm.selectedCity,
                                  callback: function($$v) {
                                    _vm.selectedCity = $$v
                                  },
                                  expression: "selectedCity"
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            ref: "province",
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.address.province")} *`,
                              rules: [_vm.requiredRules()],
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text",
                              disabled: ""
                            },
                            model: {
                              value: _vm.userData.prov,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "prov", $$v)
                              },
                              expression: "userData.prov"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-dialog",
                            {
                              attrs: {
                                transition: "scale-transition",
                                width: "290px"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  value: _vm.birthDate,
                                                  "append-icon": "$calendar",
                                                  label: `${_vm.$t(
                                                    "profile.birthDate"
                                                  )}`,
                                                  readonly: "",
                                                  outlined: "",
                                                  rounded: "",
                                                  dense: "",
                                                  rules: []
                                                },
                                                on: {
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    _vm.birthDateCal = true
                                                  }
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2489414117
                              ),
                              model: {
                                value: _vm.birthDateCal,
                                callback: function($$v) {
                                  _vm.birthDateCal = $$v
                                },
                                expression: "birthDateCal"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  max: _vm.maxBirthDate,
                                  "show-current": _vm.pickerDate,
                                  locale: "it-IT",
                                  "no-title": "",
                                  color: "primary",
                                  format: "YYYY-MM-DD"
                                },
                                on: {
                                  change: function($event) {
                                    _vm.birthDateCal = false
                                  }
                                },
                                model: {
                                  value: _vm.userData.birthDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.userData, "birthDate", $$v)
                                  },
                                  expression: "userData.birthDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            staticClass: "mt-2",
                            attrs: {
                              items: _vm.genders,
                              "item-text": "label",
                              "item-value": "code",
                              label: `${_vm.$t("profile.gender")}`,
                              outlined: "",
                              rounded: "",
                              dense: "",
                              rules: [],
                              disabled: _vm.isFieldDisabled
                            },
                            model: {
                              value: _vm.userData.sex,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "sex", $$v)
                              },
                              expression: "userData.sex"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              rules: _vm.phoneRules,
                              label: `${_vm.$t(
                                "profile.contacts.mobilePhone"
                              )}`,
                              outlined: "",
                              rounded: "",
                              dense: "",
                              disabled: _vm.isFieldDisabled
                            },
                            model: {
                              value: _vm.userData.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "phone", $$v)
                              },
                              expression: "userData.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "v-container",
                    { staticClass: "row" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "my-3 pa-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-weight-semibold",
                              staticStyle: { "font-size": "15pt" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("register.user.title")) +
                                  " "
                              )
                            ]
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("register.user.insertYourCredential")
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "font-weight-semibold text-uppercase" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("profile.header.loginData")) +
                              " "
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "info-icon",
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  _vm.infoWindow = true
                                }
                              }
                            },
                            [_vm._v("$info")]
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "300" },
                              model: {
                                value: _vm.infoWindow,
                                callback: function($$v) {
                                  _vm.infoWindow = $$v
                                },
                                expression: "infoWindow"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [_vm._v(_vm._s(_vm.$t("common.warning")))]
                                  ),
                                  _c("v-card-text", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("profile.email.infoWindow")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.infoWindow = false
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("common.ok")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.email.newEmail")} *`,
                              rules: _vm.emailRules,
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.userData.email,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "email", $$v)
                              },
                              expression: "userData.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pb-5",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.email.emailConfirm")}`,
                              hint: _vm.$t(
                                "register.card.step-0.placeholder.emailHint"
                              ),
                              "persistent-hint": "",
                              rules: _vm.emailConfirmRules,
                              outlined: "",
                              rounded: "",
                              dense: ""
                            },
                            on: {
                              paste: function($event) {
                                $event.preventDefault()
                              }
                            },
                            model: {
                              value: _vm.emailConfirm,
                              callback: function($$v) {
                                _vm.emailConfirm = $$v
                              },
                              expression: "emailConfirm"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pb-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.password")} *`,
                              "append-icon": _vm.showPassword
                                ? "$eye"
                                : "$eyeOff",
                              type: _vm.showPassword ? "text" : "password",
                              rules: _vm.passwordRules,
                              hint: _vm.$t(
                                "register.card.step-0.placeholder.passwordHint"
                              ),
                              "persistent-hint": "",
                              required: "",
                              outlined: "",
                              dense: "",
                              rounded: ""
                            },
                            on: { "click:append": _vm.toggleShowPassword },
                            model: {
                              value: _vm.userData.password,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "password", $$v)
                              },
                              expression: "userData.password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.passwordConfirm")} *`,
                              "append-icon": _vm.showPassword
                                ? "$eye"
                                : "$eyeOff",
                              type: _vm.showPassword ? "text" : "password",
                              rules: _vm.passwordConfirmRules,
                              required: "",
                              outlined: "",
                              dense: "",
                              rounded: ""
                            },
                            on: {
                              "click:append": _vm.toggleShowPassword,
                              paste: function($event) {
                                $event.preventDefault()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 3
                ? _c(
                    "v-container",
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "font-weight-semibold",
                          staticStyle: { "font-size": "17pt" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("profile.privacySectionTitle")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "my-3 privacy-container",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("div", { staticClass: "font-weight-semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("profile.rulesGalaClubTitle")) +
                                " "
                            )
                          ]),
                          _vm.userData.fidelity
                            ? _c("i18n", {
                                staticClass: "pre-wrap mt-2 text-body-2",
                                attrs: {
                                  path: "profile.disclaimerSubtitle",
                                  tag: "p"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "link1",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-decoration-underline",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.handleRedirect(
                                                    "https://www.galasupermercati.it/privacy"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "profile.disclaimerSubtitleLink"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "link2",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-decoration-underline",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.handleRedirect(
                                                    "https://www.galasupermercati.it/informativa-carta-fedelta"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "profile.disclaimerSubtitleLink2"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2696578298
                                )
                              })
                            : _c("v-checkbox", {
                                staticClass: "disclaimer-checkbox",
                                attrs: { rules: _vm.checkboxRules },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("i18n", {
                                            staticClass:
                                              "pre-wrap mt-2 text-body-2",
                                            staticStyle: { color: "black" },
                                            attrs: {
                                              path:
                                                "profile.disclaimerMainReduced1",
                                              tag: "span"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "link1",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-decoration-underline font-weight-semibold",
                                                          staticStyle: {
                                                            "font-size": "13px"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleRedirect(
                                                                "https://www.galasupermercati.it/regolamento-carta-fedelta"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "profile.disclaimerMainReduced1Link"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                },
                                                {
                                                  key: "link2",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-decoration-underline",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleRedirect(
                                                                "https://www.galasupermercati.it/regolamento-carta-fedelta"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "profile.disclaimerMainLink2"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              2871381053
                                            )
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1781125625
                                ),
                                model: {
                                  value: _vm.disclaimerMain,
                                  callback: function($$v) {
                                    _vm.disclaimerMain = $$v
                                  },
                                  expression: "disclaimerMain"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }