var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-detail" },
    [
      !_vm.submitted
        ? _c(
            "v-form",
            {
              ref: "myForm",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.submit.apply(null, arguments)
                }
              },
              model: {
                value: _vm.formValid,
                callback: function($$v) {
                  _vm.formValid = $$v
                },
                expression: "formValid"
              }
            },
            [
              _c("v-form-base", {
                attrs: { model: _vm.formModel, schema: _vm.schema }
              }),
              !_vm.previewMode
                ? _c(
                    "div",
                    { staticClass: "form-detail-footer d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        { attrs: { color: "primary", type: "submit" } },
                        [_vm._v("Invia")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { ref: "thankYou", staticClass: "thank-you" },
            [
              _vm.name ? _c("h3", [_vm._v(_vm._s(_vm.name))]) : _vm._e(),
              _c("v-alert", { attrs: { type: "success" } }, [
                _vm._v(" " + _vm._s(_vm.descr) + " ")
              ])
            ],
            1
          ),
      _c("ResponseMessage", {
        staticClass: "mt-7",
        attrs: { response: _vm.responseMessage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }