var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "subscribe-block-wrap", style: _vm.bgImageStyle },
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.description) } }),
      _c(
        "div",
        { staticClass: "pb-2" },
        [_c("LoginBtn", { attrs: { label: _vm.buttonLabel } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }