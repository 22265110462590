global.config = {
  one_signal_app_id: process.env.VUE_APP_ONE_SIGNAL_ID,
  one_signal_app_id_test: process.env.VUE_APP_ONE_SIGNAL_ID_TEST,
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 1,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: [
    "galasupermercatimobile.it",
    "localhost",
    "www.galasupermercatimobile.it",
    "qa.galasupermercatimobile.it"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsnappgala://",
  deeplinks: {
    "/coupon/personalDiscounts": "/coupon/personalDiscounts"
  },
  // filterProductClasses: [1, 2, 3, 4, 6],
  updateCart: false,
  dialogMaxWidth: 530,
  addToFavoritesWidth: 350,
  analyticsClass: "analyticsServiceGTM",
  cartItemInfos: ["size", "customWeight"],
  acceptAlternatives: false,
  productVoteMaxRating: 5,
  categoryTreeDepth: 3,
  // facebookClientId: "1250974205313975",
  // googleClientId:
  //   "1061314893204-tbicvnnsq74cjiarigmq3jiilqu56b0v.apps.googleusercontent.com",
  defaultPaymentTypeId: 12,
  socialLogo: "/logo/logo_mobile.png",
  defaultStoreName: "Catalogo Globale"
};
