<template>
  <v-container fluid class="pb-3 pt-6 px-3">
    <div class="text-center">
      <h4>{{ (title)?title:$t('common.error') }}</h4>      
      <span>{{ message }}</span>
      <v-btn
        depressed
        color="secondary"
        block
        rounded
        outlined
        class="mt-8 error-button"
        @click="closeDialog()"
        >{{ $t("common.ok") }}</v-btn
      ></div>
  </v-container>
</template>
<style lang="scss">

.error-button{
  border:1px solid var(--v-secondary-base)  !important;
}</style>

<script>
export default {
  name: "ErrorMessage",
  props: { message: { type: String, required: true },title: { type: String, required: false } },
  data() {
    return {
      
    };
  },
  methods:{
    closeDialog(){
      this.$emit("submit",false);
    }
  }
}
  
</script>
