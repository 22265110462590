var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "calendar-container" },
      [
        _vm._l(_vm.segments, function(segment, index) {
          return [
            _vm.calendar[index] == "N"
              ? _c("div", { key: index, staticClass: "image-container" }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: "/logo/calendar.svg" }
                  }),
                  _c("div", { staticClass: "overlay-text" }, [
                    _vm._v(_vm._s(_vm.$t("calendarBar.month")))
                  ]),
                  _c("div", { staticClass: "overlay-text-segment" }, [
                    _vm._v(_vm._s(segment))
                  ])
                ])
              : _vm._e(),
            _vm.calendar[index] == "Y"
              ? _c("div", { key: index, staticClass: "image-container" }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: "/logo/calendar-blue.svg" }
                  }),
                  _c("div", { staticClass: "overlay-text" }, [
                    _vm._v(_vm._s(_vm.$t("calendarBar.month")))
                  ]),
                  _c("div", { staticClass: "overlay-text-segment blue" }, [
                    _vm._v(_vm._s(segment))
                  ])
                ])
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }