var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "NavigationCard-wh-row",
      attrs: { id: `NavigationCard-wh-${_vm.config.categoryBlockId}` }
    },
    [
      _c("ebsn-style", {
        attrs: {
          target: _vm.config,
          path: "metaData.categoryblocktype_NavigationCard.CSS_STYLE",
          styleWrapper: `#NavigationCard-wh-${_vm.config.categoryBlockId}`
        }
      }),
      _vm.$ebsn.meta(
        _vm.config,
        "categoryblocktype_NavigationCard.IMG_URL",
        false
      )
        ? _c(
            "v-card",
            {
              staticClass: "nav-card d-flex",
              attrs: { to: _vm.calcLinkToGo(_vm.config, _vm.$ebsn.meta) }
            },
            [
              _c("div", { staticClass: "card-image-wraper" }, [
                _c("img", {
                  staticClass: "card-image",
                  attrs: {
                    src: _vm.$ebsn.meta(
                      _vm.config,
                      "categoryblocktype_NavigationCard.IMG_URL",
                      false
                    ),
                    onerror: "this.onerror=null;this.src='/no-icon.png'"
                  }
                })
              ]),
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _c("ebsn-meta", {
                      class: "category-block-title",
                      attrs: {
                        target: _vm.config,
                        path: "categoryblocktype_NavigationCard.TITLE",
                        tag: "span"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "subtitle" },
                  [
                    _c("ebsn-meta", {
                      attrs: {
                        target: _vm.config,
                        path: "categoryblocktype_NavigationCard.DESCRIPTION",
                        tag: "span"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        : _c(
            "v-card",
            {
              staticClass: "nav-card d-flex",
              attrs: { to: _vm.calcLinkToGo(_vm.config, _vm.$ebsn.meta) }
            },
            [
              _c("div", { staticClass: "card-content-image" }, [
                _vm.$ebsn.meta(
                  _vm.config,
                  "categoryblocktype_NavigationCard.TITLE",
                  false
                )
                  ? _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _c("ebsn-meta", {
                          class: "category-block-title",
                          attrs: {
                            target: _vm.config,
                            path: "categoryblocktype_NavigationCard.TITLE",
                            tag: "span"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ebsn.meta(
                  _vm.config,
                  "categoryblocktype_NavigationCard.DESCRIPTION",
                  false
                )
                  ? _c(
                      "div",
                      { staticClass: "subtitle" },
                      [
                        _c("ebsn-meta", {
                          attrs: {
                            target: _vm.config,
                            path:
                              "categoryblocktype_NavigationCard.DESCRIPTION",
                            tag: "span"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }