<template>
  <v-container fluid class="pb-3 pt-6 px-3">
    <div class="text-center">
      <h4>{{ (title)}}</h4>      
      <span>{{ message }}</span>
      <v-btn
        color="primary"
        block
        rounded
        class="mt-3"
        @click="closeDialog(true)"
        >{{ button_yes }}</v-btn
      >
      <v-btn
        color="primary"
        block
        rounded
        outlined
        class="mt-3"
        @click="closeDialog(false)"
        >{{ button_no }}</v-btn
      ></div>
  </v-container>
</template>
<style lang="scss">

.error-button{
  border:1px solid var(--v-secondary-base)  !important;
}</style>

<script>
export default {
  name: "ConfirmMessage",
  props: { message: { type: String, required: true },title: { type: String, required: false },button_yes: { type: String, required: false },button_no: { type: String, required: false } },
  data() {
    return {
      
    };
  },
  methods:{
    closeDialog(value){
      this.$emit("submit",value);
    }
  }
}
  
</script>
