var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "leaflet-card",
      attrs: {
        rounded: "",
        to: {
          name: "LeafletDetails",
          params: { leafletId: _vm.leaflet.leafletId }
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("v-img", {
            staticClass: "rounded-lg",
            attrs: {
              alt: _vm.cover.alt,
              eager: "",
              contain: "",
              src: _vm.cover.url
            }
          }),
          _c("h4", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.leaflet.name))]),
          _c("div", [
            _vm._v(
              " Dal " +
                _vm._s(_vm.formattedFromDate) +
                " al " +
                _vm._s(_vm.$dayjs(_vm.leaflet.toDate).format("DD MMMM")) +
                " "
            )
          ]),
          _vm.leaflet.leafletStatus == true
            ? _c("div", { staticStyle: { color: "#FFA500" } }, [
                _c("li", [_c("b", [_vm._v("In arrivo")])])
              ])
            : _vm.leaflet.leafletStatus == false
            ? _c("div", { staticStyle: { color: "green" } }, [
                _c("li", [_c("b", [_vm._v("In corso")])])
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }