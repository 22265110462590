var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "promo-box d-flex",
      class: _vm.props.warehousePromo.view.cssClass
    },
    [
      _c("span", {
        staticClass: "promo-header",
        domProps: { innerHTML: _vm._s(_vm.props.warehousePromo.view.header) }
      }),
      _c("span", {
        staticClass: "promo-body",
        domProps: { innerHTML: _vm._s(_vm.props.warehousePromo.view.body) }
      }),
      _c("span", {
        staticClass: "promo-footer",
        domProps: { innerHTML: _vm._s(_vm.props.warehousePromo.view.footer) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }