var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "proposal-btn",
      style: _vm.cardStyle,
      attrs: { rounded: "md", href: _vm.link, outlined: "" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleLink.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "img-container rounded-md",
          class: _vm.$ebsn.meta(
            _vm.proposal,
            "metaData.category_proposal_type.INVERTED",
            false
          )
            ? "order-2"
            : "order-1",
          style: _vm.getIconSectionStyle()
        },
        [
          _vm.proposal.img
            ? _c("embed", {
                staticClass: "img-embedded",
                style: `max-width: 100%`,
                attrs: {
                  src: _vm.proposal.img,
                  alt: _vm.proposal.imgAlt,
                  title: _vm.proposal.imgDescription
                }
              })
            : _vm._e()
        ]
      ),
      _vm.proposal.name
        ? _c("div", {
            staticClass: "btn-label",
            class: _vm.$ebsn.meta(
              _vm.proposal,
              "metaData.category_proposal_type.INVERTED",
              false
            )
              ? "order-1"
              : "order-2",
            domProps: { innerHTML: _vm._s(_vm.proposal.name) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }