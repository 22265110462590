var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "banner-tab", attrs: { outlined: "" } },
    [
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_BannerTab.TITLE",
          tag: "h2"
        }
      }),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_BannerTab.DESCRIPTION",
          tag: "div"
        }
      }),
      _c(
        "div",
        { class: { "custom-vertical-tab d-flex": _vm.isVertical } },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                color: _vm.$ebsn.meta(
                  _vm.config,
                  "categoryblocktype_BannerTab.TABS_COLOR"
                ),
                vertical: _vm.isVertical,
                "hide-slider": ""
              },
              model: {
                value: _vm.selectedProposal,
                callback: function($$v) {
                  _vm.selectedProposal = $$v
                },
                expression: "selectedProposal"
              }
            },
            [
              _c("v-tabs-slider"),
              _vm._l(_vm.config.proposals, function(proposal) {
                return _c("v-tab", { key: proposal.id }, [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("div", { staticClass: "tab-title" }, [
                      _vm._v(_vm._s(proposal.name))
                    ]),
                    _c("div", { staticClass: "tab-description" }, [
                      _vm._v(_vm._s(proposal.descr))
                    ])
                  ])
                ])
              })
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "d-none d-lg-flex",
              attrs: { vertical: "" },
              model: {
                value: _vm.selectedProposal,
                callback: function($$v) {
                  _vm.selectedProposal = $$v
                },
                expression: "selectedProposal"
              }
            },
            _vm._l(_vm.config.proposals, function(proposal) {
              return _c(
                "v-tab-item",
                { key: proposal.id, attrs: { eager: "" } },
                [
                  _c(_vm.getProposalModelName(proposal), {
                    tag: "component",
                    staticClass: "h-100",
                    attrs: { proposal: proposal }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }