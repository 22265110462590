var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_BannerGrid.TITLE",
          tag: "h2"
        }
      }),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_BannerGrid.DESCRIPTION",
          tag: "div"
        }
      }),
      _c(
        "v-row",
        { staticClass: "banner-grid-row" },
        _vm._l(_vm.config.proposals, function(proposal, index) {
          return _c(
            "v-col",
            {
              key: proposal.id,
              staticClass: "banner-grid-col",
              attrs: {
                cols: _vm.getColData(index, "xs"),
                sm: _vm.getColData(index, "sm"),
                md: _vm.getColData(index, "md"),
                lg: _vm.getColData(index, "lg"),
                xl: _vm.getColData(index, "xl")
              }
            },
            [
              _c(_vm.getProposalModelName(proposal), {
                tag: "component",
                staticClass: "h-100",
                attrs: { proposal: proposal }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }