var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", [_vm._v(_vm._s(_vm.data.label))]),
      _c(
        "v-radio-group",
        {
          attrs: { rules: _vm.rules },
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.data.options, function(option) {
          return _c("v-radio", {
            key: option.key,
            attrs: { label: option.label, value: option.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }